

$('#btn_change_plan').on('click', function(e) {
  e.preventDefault();
  e.stopPropagation();
  // var that = $(this);
  // var def_text = that.text();

  // $(this).prepend('<i class="fa fa-spinner fa-pulse"></i> ');

  __request_change_subscription();

});

$('#btn_request_purchase_page').on('click', function(e) {
  e.preventDefault();
  e.stopPropagation();
  // var that = $(this);
  // var def_text = that.text();

  // $(this).prepend('<i class="fa fa-spinner fa-pulse"></i> ');

  __request_purchase_page();

});


function __request_change_subscription() {
	$.get( DOMAIN+"/ajax/change_subscription", function( result ) {

	  if(typeof result.status != 'undefined' && result.status) {
	  	var html = result.html;

	  	// Piwik
	    if (typeof _paq != "undefined") {
	      _paq.push(['trackEvent', "Subscription", "subscribed"]);
	    }

	  	__display_subscription(html, true);

		} 
		
		// else if(result.error == 'no phone') {

	  // 	var html = result.html;
	  // 	__display_phone_subscription(html);

		// } 
		
		else {
			__display_subscription('<dev class="text-danger text-center" style="padding:15px;"><h4>លោកអ្នកមិនអាច ផ្លាស់ប្តូរគម្រោងពេលនេះបានទេ</h4></dev>', false);
			
	  }
	});
}

function __request_purchase_page() {
	$.get( DOMAIN+"/ajax/request_purchase_page", function( result ) {

	  if(typeof result.status != 'undefined' && result.status) {
	  	var html = result.html;

	  	__display_subscription(html, true);

		} 
		// else if(result.error == 'no phone') {

	  // 	var html = result.html;
	  // 	__display_phone_subscription(html);

		// } 
		else {
			__display_subscription('<dev class="text-danger text-center" style="padding:15px;"><h4>លោកអ្នកមិនអាច ផ្លាស់ប្តូរគម្រោងពេលនេះបានទេ</h4></dev>', false);
			
	  }
	});
}


function __display_phone_subscription(html)
{
	subscription_root_element();
	var detail_el = $('#episode__detail');
	detail_el.fadeIn();

	var body_el = detail_el.find('.detail_body');
	body_el.html(html);

	$('#form_phone').submit(function(e) {
		e.preventDefault();
		var form = $(this);

		var input = $('input[name=input-phone]');
		var phone = input.val();

		form.find('.control-label').hide();
		input.parent().removeClass('has-error');

		if (valid_phone(phone)) {
			form.loading();

			$.ajax({
		    url: DOMAIN+"/ajax/verify_phone", 
		    method: "POST", 
		    data: {
		    	phone: phone
		    }, 
		    success: function(result){
		    	form.stop_loading();
					if (typeof result.status != "undefined" && result.status) {
						body_el.find('#send_phone').hide();
						body_el.find('#verify_phone').show();

						handle_form_verify_phone(phone);
					} else {
						form.find('.control-label').text(result.data).show();
						input.parent().addClass('has-error');
					}
		    }
		  });

		} else {
			form.find('.control-label').show();
			input.parent().addClass('has-error');
		}

	});

	detail_el.find('.blocker').on('click', function(e) {
		e.preventDefault();
		detail_el.fadeOut();
		detail_el.find('.detail_body').html('');
		detail_el.remove();
	});


	detail_el.find('input.number').keydown(function (e) {
      // Allow: backspace, delete, tab, escape, enter and .
      if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 110, 190]) !== -1 ||
           // Allow: Ctrl+A
          (e.keyCode == 65 && e.ctrlKey === true) ||
           // Allow: Ctrl+C
          (e.keyCode == 67 && e.ctrlKey === true) ||
           // Allow: Ctrl+X
          (e.keyCode == 88 && e.ctrlKey === true) ||
           // Allow: home, end, left, right
          (e.keyCode >= 35 && e.keyCode <= 39)) {
               // let it happen, don't do anything
               return;
      }
      // Ensure that it is a number and stop the keypress
      if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
          e.preventDefault();
      }
  });



}


function handle_form_verify_phone(phone) {

	$('#form_verify_phone').submit(function(e) {
  	e.preventDefault();
		var form = $(this);

		var input = $('input[name=input-code]');
		var code = input.val();

		form.find('.control-label').hide();
		input.parent().removeClass('has-error');

		if (valid_code(code)) {
			form.loading();

			$.ajax({
		    url: DOMAIN+"/ajax/verify_sms_code", 
		    method: "POST", 
		    data: {
		    	phone: phone,
		    	code: code,
		    }, 
		    success: function(result){
		    	form.stop_loading();
					if (typeof result.status != "undefined" && result.status) {
						
						__request_change_subscription();

					} else {
						form.find('.control-label').text(result.data).show();
						input.parent().addClass('has-error');
					}
		    }
		  });

		} else {
			form.find('.control-label').show();
			input.parent().addClass('has-error');
		}

  });

}


function valid_phone (str) 
{
	return str == parseInt(str, 10) 
					&& str.length >= 9 
					&& str.length <= 10;
}


function valid_code (str) 
{
	return str == parseInt(str, 10) 
					&& str.length == 6;
}


function __display_subscription(html, status)
{
	subscription_root_element();
	var detail_el = $('#episode__detail');
	detail_el.fadeIn();

	var body_el = detail_el.find('.detail_body');
	body_el.html(html);

	if(status == false) {
		detail_el.find('.blocker').on('click', function(e) {
			e.preventDefault();
			close_subscription();
		});
	}
	

	detail_el.find('#close_subscription').on('click', function(e) {
		e.preventDefault();
		before_close_subscription();
	});


	function before_close_subscription() {
		detail_el.find('.detail_body').html(waiting_subscription());
		setTimeout( close_subscription,  $('.payment_timeout').val());
	}

	function close_subscription() {
		detail_el.fadeOut();
		detail_el.find('.detail_body').html('');
		detail_el.remove();
		
		__refresh_user();
		
	}
}

function subscription_root_element() {

	if (! $('#episode__detail').lenght ) {

		var str = '<div id="episode__detail" class="episode__detail subscription">'+
					'<div class="blocker"></div>'+
					'<div class="detail_body">'+
					'</div>'+
				 '</div>';

		$('body').append(str);
	}
	
}

function waiting_subscription() {
	return '<div class="text-center waiting">'+
						'<p><i class="fa fa-spinner fa-pulse"></i></p>'+
						'<p><span>សូមមេត្តារង់ចាំ យើងខ្ញុំកំពុងពិនិត្យ លើការផ្លាស់ប្តូរគម្រោងរបស់អ្នក..</span></p>'+
					'</div>';
}

function __refresh_user() {
	$.post( DOMAIN+"/ajax/refresh_user", function( res ) {
	  if (res.status) { 	
	  	location.reload(); 
	  } else {
	  	console.log("Error refresh user");
	  }
	});
}



