
var TIMER = $("#tvc_duration").val();
var timeout;
var hover = true;

function timeout_trigger () {
	( function( $ ) {
		timer = parseInt( $( '#timer' ).text() );
		$( '#timer' ).text( timer - 1 );
		if ( timer == 0 ) {
			$('.fancybox-title-float-wrap').hide();
			$('.fancybox-close').show();
			
			//$('.fancybox-title fancybox-title-float-wrap').show();
			//$.fancybox.close();
			//$( '.fancybox-opened' ).hide();
			//$( '.fancybox-overlay.fancybox-overlay-fixed' ).hide();
			//$( 'html' ).removeClass( 'fancybox-margin' );
			//$( 'html' ).removeClass( 'fancybox-lock' );
			clearInterval( timeout );
		}
	} ) ( jQuery );	
}

function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+d.toGMTString();
    document.cookie = cname + "=" + cvalue + "; " + expires;
}

function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i=0; i<ca.length; i++) {
        var c = ca[i].trim();
        if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
    }
    return "";
}

function get_date ( pdate  ) {
	var currentDate = new Date( pdate );
	var day = currentDate.getDate();
	var month = currentDate.getMonth() + 1;
	var year = currentDate.getFullYear();
	var fdate =  year + '' + month + '' + day;
	return parseInt( fdate );
}

function get_tomorrow () {
	return get_date( new Date().getTime() + 24 * 60 * 60 * 1000 );
}

function get_today () {
	return get_date( new Date().getTime() );
}


function sb_ads_startup(url) {
	setCookie("sb_ads_startup", 1, get_tomorrow ());
	$.fancybox.open(
		[
			{
				href : url,
				type: 'html',
				title : 'This ads will be closed in <span id="timer">' + ( TIMER ) + '</span> seconds.',
				helpers     : { 
					overlay : {closeClick: false}, // prevents closing when clicking OUTSIDE fancybox

				}
			},		
		],
		{
			afterShow: function () {
				$('.fancybox-close').hide();
				$('.episode__detail, .episode__detail__audio, .quote__generator').css('z-index', '1000')
				$( '.fancybox-image' ).css( 'cursor', 'pointer' );
				timeout = setInterval( 'timeout_trigger()', 1000);
				
			},
			afterClose: function () {
				//$.fancybox.close( true );
				clearInterval( timeout );
			}
		}
	);
}



