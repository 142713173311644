$('#btn_renting').click(function() {
  story_id = $(this).data('story_id');
  $.ajax({
    url: DOMAIN + '/renting' + '?story_id=' + story_id, 
    method: "GET",
    success: function(result) {
      if (result.status) {
        $("#renting_modal").modal();
        $("#renting_modal").html(result.html);
        $(".panel_renting").append('<div class="modal-footer"><a href="javascript:;"  class="btn btn-default" data-dismiss="modal">Close</a></div>');
      }
    },
    error: function(err) {
      console.log(err);
    }
  });
});

$('#btn_purchase').click(function() {
  story_id = $(this).data('story_id');
  $.ajax({
    url: DOMAIN + '/purchase' + '?story_id=' + story_id, 
    method: "GET",
    success: function(result) {

      if (result.status) {
        $("#renting_modal").modal();
        $("#renting_modal").html(result.html);
        $(".panel_renting").append('<div class="modal-footer"><a href="javascript:;"  class="btn btn-default" data-dismiss="modal">Close</a></div>');
      }

    },
    error: function(err) {
      console.log(err);
    }
  });
});

function _waiting_modal() {
  var str = '<div id="waiting_message">'+
              '<div class="blocker"></div>'+
              '<div id="body_waiting_message">'+
              '<div class="text-center waiting">'+
              '<p><i class="fa fa-spinner fa-pulse"></i></p>'+
              '<p><span>សូមមេត្តារង់ចាំ យើងខ្ញុំកំពុងពិនិត្យ លើការផ្លាស់ប្តូររបស់អ្នក..</span></p>'+
              '</div>'+
            '</div>';
  return str;
}

$('#renting_modal').on('hidden.bs.modal', function () {
  location.reload();
});
