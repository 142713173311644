$('.toogle-sidebar').on('click', function(e) {
  e.preventDefault();
  toggleSidebar();
});

$('body .content_blocker').on('click', function(e) {
	e.preventDefault();
	toggleSidebar();
});


function toggleSidebar() {
	$('body').toggleClass("min-sidebar");
  $('.toogle-sidebar').find('i').toggleClass("fa-reorder fa-times");
}

