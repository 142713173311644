SBAdvertisement = function(options) {
  this.init(options);
}

$.extend(SBAdvertisement.prototype, {

  default: {
    app_code: null, 
    default_ads: null, 
    gateway_banners: null, 
    menu_ads: null, 
    tag_ads: null, 
    is_landing_page: false,
    is_menu_page: false,
    is_tag_page: false,
    is_article_page: false,
    curent_category: null,
    curent_tag: null,
    curent_post: null
  },

  // object variables
  options: new Object(),

  init: function(options) {
   // do initialization here
   this.options = this.merge(this.default, options);
  },

  merge: function(obj, src) {
      for (var key in src) {
          if (src.hasOwnProperty(key)) obj[key] = src[key];
      }
      return obj;
  },

  consoleOptions: function() {
   // an example object method
   console.log(this.options);
  },
  
  is_expired: function(date) {
    var now = new moment().valueOf();
    var date = new moment.utc(date).valueOf();
    return (now >= date);
  },

  replace_sponsor_ads: function(ads) {
    var default_ads = this.options.default_ads;
    if (default_ads.length < 3) {
      return;
    }

    var campaign_date = ads[0].campaign.end_date;
    if (this.is_expired(campaign_date)) {
      return;
    }

    switch(ads[0].campaign.option) {
      case "half":
        default_ads[2] = ads[0];
        break;

      case "full":
        default_ads[1] = ads[0];
        default_ads[2] = ads[0];
        break;

      case "special":
      // console.log(ads);
        default_ads[0] = ads[0];
        default_ads[1] = ads[1];
        default_ads[2] = ads[1];

        this.replace_special_gateway_banners();
        break;
    }
    
  },

  replace_special_gateway_banners: function()
  {
    var gateway_banners = this.options.gateway_banners;
    // console.log(gateway_banners.length);

    $.each(gateway_banners, function(index, banner) {
      if(typeof banner !== "undefined" && banner.campaign.option != "special") {
        gateway_banners.splice(index, 1);
      }
    });
    // console.log(gateway_banners);
  },

  handle_menu_page: function()
  {
    var category = this.options.curent_category;
    if (!category) {
      return;
    }
    this.handle_menu_sponsor(category);
  },


  handle_menu_sponsor: function(menu)
  {
    var menu_id = menu.id;
    var menu_ads = this.options.menu_ads;
    if(typeof menu_ads[menu_id] === 'undefined') {
        return false; 
    }
    menu_ad = menu_ads[menu_id];
    this.replace_sponsor_ads(menu_ad);
    return true;
  },

  handle_tag_page: function()
  {
    var tag = this.options.curent_tag;
    if (!tag) {
      return;
    }
    this.handle_tag_sponsor(tag);
  },

  handle_tag_sponsor: function(tag)
  {
    var tag_ads = this.options.tag_ads;
    if(typeof tag_ads[tag.id] === 'undefined') {
        return false; 
    }
    tag_ad = tag_ads[tag.id];
    this.replace_sponsor_ads(tag_ad);
    return true;
  },


  handle_article_page: function()
  {
    var post = this.options.curent_post;
    if (!post) {
      return;
    }

    var tags = post.tags;
    var found_ad = false;
    var that = this;
    if(tags.length > 0) {
      $.each(tags, function(index, tag) {
        found_ad = that.handle_tag_sponsor(tag);
        if(found_ad) {
          // Stop loop
          return false;
        }
      });
    }

    if(found_ad) {
      return;
    }

    var app_code = this.options.app_code;

    var menu = post.menus[app_code];

    this.handle_menu_sponsor(menu);
    
  },

  ad_zone_handler: function() {

    if(this.options.is_menu_page) {

      this.handle_menu_page();

    } else if(this.options.is_tag_page) {

      this.handle_tag_page();

    } else if(this.options.is_article_page) {

      this.handle_article_page();

    }

  },

   display: function() {

    this.ad_zone_handler();
    for (i = 0; i < this.options.default_ads.length; i++) {
      var id = i + 1;
      if(id < 10) {id = "0"+id}
      this.create_ads("ad_zone_"+id, this.options.default_ads[i].zone);
    }

    for (i = 0; i < this.options.gateway_banners.length; i++) {
      var id = i + 1;
      if(id < 10) {id = "0"+id}
      this.create_ads("gateway_banner_"+id, this.options.gateway_banners[i].campaign.gateway_banner);
    }

   },

   create_ads: function(ele_id, ads) {

    var element = $('body').find('#'+ele_id+':visible');

    // console.log(ele_id);
    var start_up_ads = getCookie('sb_ads_startup');
    start_up_ads = parseInt(start_up_ads);

    count_sua = isNaN(start_up_ads) ? 0 : start_up_ads;
    // console.log(count_sua);

    if(element.length < 1 && count_sua <= 0)
    {
      element = $('body').find('#'+ele_id+'.start_up_ads');
    }
    // console.log(element);
    // console.log(ads);

    if (typeof ads != 'undefined' && ads ) {
      element.attr('zone', ads).html( this.template(ads) );
    }

   },

   template: function(ad_zone) {
    return '<ins data-revive-zoneid="'+ad_zone+'"  data-revive-id="4b9ca95baee4430ad25d6d07da6464a5"></ins><script async src="//ads.sabay.com/openx/www/delivery/asyncjs.php">';

   }

});

$('#ads-close').click(function(){
  $(this).parent().remove();
  return false;
});


var TIMER = 5;
var timeout;
var hover = true;
( function( $ ) {
  $( document ).ready( function () {
    //$.cookie("expired", null, { path: '/' });
  } );
} ) ( jQuery );

takeover = function ( smallimage, bigimgurl ) {
  //console.log(smallimage+ " "+ bigimgurl);
  ( function( $ ) {
    //$( [ bigimgurl ] ).preload();

    $( '.ads a' ).each( function ( i ) {
      $( this ).mouseover( function () {
        var $href = $( this ).attr( 'href' );
        var $img = $( 'img', this );
        var $src = $img.attr( 'src' );
        var name =  get_name_from_url ( $src );
        
        if ( name == smallimage ) {
          if(hover){
            $.fancybox.open(
              [
                {
                    href : bigimgurl,
                    title : 'This ads will be closed in <span id="timer">' + TIMER + '</span> seconds.'
                  },
              ],
              {
                afterShow: function () {
                  //console.log( 'Lightbox showed!' );
                  $( '.fancybox-image' ).css( 'cursor', 'pointer' );
                  $( '.fancybox-image' ).click( function () {
                    //window.location.href = $href;
                    window.open(
                      $href,
                      '_blank' // <- This is what makes it open in a new window.
                    );
                    $.fancybox.close( true );
                  } );
                  timeout = setInterval( 'timeout_trigger()', 1000);
                },
                afterClose: function () {
                  clearInterval( timeout );
                  // console.log( 'Ads is closed' );
                }
              }
            );
            hover = false;
          }
        }

      } );
    } );
  } ) ( jQuery );
}

function show_large ( smallimage, bigimgurl ) {
  ( function( $ ) {
  $( document ).ready( function () {
    $( [ bigimgurl ] ).preload();
    $( '.ads a' ).each( function ( i ) {
      var $href = $( this ).attr( 'href' );
      var $img = $( 'img', this );
      var $src = $img.attr( 'src' );
      var name =  get_name_from_url ( $src );
      if ( name == smallimage ) {
        var expired =  getCookie( 'expired' );
        if ( expired == '' || ( expired != '' && expired < get_tomorrow() ) ) { 
        $.fancybox.open(
          [
            {
                    href : bigimgurl,
                    title : 'This ads will be closed in <span id="timer">' + ( TIMER ) + '</span> seconds.'
                },
          ],
          {
            afterShow: function () {
              $.fancybox.helpers.overlay.open();
              $( '.fancybox-image' ).css( 'cursor', 'pointer' );
              $( '.fancybox-image' ).click( function () {
                window.open(
                  $href,
                  '_blank' // <- This is what makes it open in a new window.
                );
                $.fancybox.close( true );
                $( '.fancybox-opened' ).hide();
                                  $( '.fancybox-overlay.fancybox-overlay-fixed' ).hide();
                                  $( 'html' ).removeClass( 'fancybox-margin' );
                                  $( 'html' ).removeClass( 'fancybox-lock' );
                                  clearInterval( timeout );
              } );
              timeout = setInterval( 'timeout_trigger()', 1000);
            },
            afterClose: function () {
              clearInterval( timeout );
              // console.log( 'Ads is closed' );
            }
          }
        );
        setCookie( 'expired', get_tomorrow() );
        //$( 'html' ).addClass( 'fancybox-margin' );
        //$( 'html' ).addClass( 'fancybox-lock' );
        }
        // console.log( 'Load large' );
        $( this ).hide();
        //return false;
      }
    } );
  } );
  } ) ( jQuery );
}

function get_name_from_url ( url ) {
  var name = '';
  ( function( $ ) {
    var args = url.split( '/' );
    var filename = args[ args.length -1 ];
    name = filename.split( '.' )[0];
    //console.log( name );
    //console.log( bigimgurl )
  } ) ( jQuery );
  return name;
}

( function( $ ) {
$.fn.preload = function () {
      this.each( function ( i, value ){
        //console.log( value );
          //$( 'body' ).append( $( '<img src="' + value + '" style="display: none;"/>' ) );
      });
}
} ) ( jQuery );

function timeout_trigger () {
  ( function( $ ) {
    timer = parseInt( $( '#timer' ).text() );
    $( '#timer' ).text( timer - 1 );
    if ( timer == 0 ) {
      //$.fancybox.close( true );
      $( '.fancybox-opened' ).hide();
      $( '.fancybox-overlay.fancybox-overlay-fixed' ).hide();
      $( 'html' ).removeClass( 'fancybox-margin' );
      $( 'html' ).removeClass( 'fancybox-lock' );
      clearInterval( timeout );
    }
  } ) ( jQuery ); 
}

function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+d.toGMTString();
    document.cookie = cname + "=" + cvalue + "; " + expires;
}

function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i=0; i<ca.length; i++) {
        var c = ca[i].trim();
        if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
    }
    return "";
}

function get_date ( pdate  ) {
  var currentDate = new Date( pdate );
  var day = currentDate.getDate();
  var month = currentDate.getMonth() + 1;
  var year = currentDate.getFullYear();
  var fdate =  year + '' + month + '' + day;
  return parseInt( fdate );
}

function get_tomorrow () {
  return get_date( new Date().getTime() + 24 * 60 * 60 * 1000 );
}

function get_today () {
  return get_date( new Date().getTime() );
}


// function sb_ads_startup(link, image) {

//   // console.log(image);
//   // console.log(link);
//   setCookie("sb_ads_startup", 1, get_tomorrow ());

//   $.fancybox.open(
//     [
//       {
//               href : image,
//               title : 'This ads will be closed in <span id="timer">' + ( TIMER ) + '</span> seconds.'
//           },
//     ],
//     {
//       afterShow: function () {
//         $.fancybox.helpers.overlay.open();
//         $( '.fancybox-image' ).css( 'cursor', 'pointer' );
//         $( '.fancybox-image' ).click( function () {
//           window.open(
//             link,
//             '_blank' // <- This is what makes it open in a new window.
//           );
//           $.fancybox.close( true );
//           $( '.fancybox-opened' ).hide();
//                             $( '.fancybox-overlay.fancybox-overlay-fixed' ).hide();
//                             $( 'html' ).removeClass( 'fancybox-margin' );
//                             $( 'html' ).removeClass( 'fancybox-lock' );
//                             clearInterval( timeout );
//         } );
//         timeout = setInterval( 'timeout_trigger()', 1000);
//       },
//       afterClose: function () {
//         clearInterval( timeout );

//         // console.log( 'Ads is closed' );
//       }
//     }
//   );

// }