
$(document).ready(function(){
	
	$('.btn_redeem').on('click', function(e) {
		e.preventDefault();
		if ($('body').find('#redeem_content').length < 1) {
			$('body').append(__redeem_root_element());
		}
 		var detail_el = $('#redeem_content');
 		detail_el.fadeIn();

 		window.redeem_success = false;

 		var url = DOMAIN + '/ajax/redeem_promotion_by_code';

	 	$.ajax({
	    url: url, 
	    method: 'GET', 
	    success: function(result) {

	    	var body_el = detail_el.find('.detail_body');
				body_el.html(result);
	    	
	    	$('#form_redeem').submit(function(e) {
					e.preventDefault();
					var form = $(this);

					var input = $('input[name=input-redeem]');
					var code = input.val();

					if (code.length > 0) {
						form.loading();

						$.ajax({
					    url: DOMAIN+"/ajax/redeem_promotion_by_code", 
					    method: "POST", 
					    data: {
					    	code: code
					    }, 
					    success: function(result){
					    	form.stop_loading();

					    	// Piwik
						    if (typeof _paq != "undefined") {
						      _paq.push(['trackEvent', "Promotion", "redeemed"]);
						    }

								if (typeof result.status != "undefined" && result.status) {
									form.parent().html('<h4 class="text-success"> ការប្តូរគូប៉ុង ជោគជ័យ</h4>');
									window.redeem_success = true;
								} else {
									form.parent().html('<h4 class="text-danger"> ការប្តូរគូប៉ុង បរាជ័យ</h4>');
								}
					    }
					  });

					}

				});

				$('.btn-close').on('click', function(e) {
					e.preventDefault();
					__close_redeem();
				});
	   		
	    }
	  });

		detail_el.find('.blocker').on('click', function(e) {
			e.preventDefault();
			__close_redeem();
		});

	});

});

function __redeem_root_element() {
	return '<div id="redeem_content" class="episode__detail subscription">'+
					'<div class="blocker"></div>'+
					'<div class="detail_body">'+
					'</div>'+
				 '</div>';
}

function __close_redeem() {
	var detail_el = $('#redeem_content');

	if (window.redeem_success) {
		detail_el.find('.detail_body').html(__waiting_redeem());
		window.redeem_success = false;
		
		setTimeout( __refresh_user,  10000);
	} else {
		__close(detail_el);
	}
}


function __waiting_redeem() {
	return '<div class="text-center waiting">'+
						'<p><i class="fa fa-spinner fa-pulse"></i></p>'+
						'<p><span>សូមមេត្តារង់ចាំ យើងខ្ញុំកំពុងពិនិត្យ លើការប្តូរគូប៉ុងរបស់អ្នក..</span></p>'+
					'</div>';
}

