
(function($){
  $.fn.extend({ 
    inactivityTime: function() {

    	// var time_inactivity;
		  // window.onload = resetTimer;
		  // DOM Events
		  // document.onmousemove = resetTimer;
		  // document.onkeypress = resetTimer;

		  init = function() {
	      resetTimer();
	    }

		  // if(__is_mobile()) {
		  //   document.addEventListener("touchmove", function(event) {
		  //     resetTimer();
		  //   });
		  // }

		  // if (typeof window.ap != "undefined") {
		  //   window.ap.on('playing', function(){
		  //     resetTimer();
		  //   });
		  // }

		  function logout() {
		    // console.log("You are now logged out.")
		    // location.href = DOMAIN + '/logout'
		    $.post( DOMAIN + "/ajax/refresh_user" , function( data ) {
				  if (typeof data.status !="undefined" && 
				  	data.status !== "unauthorized") {
				  	resetTimer();	
				  }
				});
		    	    
		  }

		  function resetTimer() {

		  	if (typeof time_inactivity != "undefined") {
			    // console.log(time_inactivity);
			    clearTimeout(time_inactivity);
			  }
			  
		    time_inactivity = setTimeout(function(){ logout() }, (1000 * 60 * 10) );
		    // 1000 milisec = 1 sec
		    // this is 10 min
		  }
     

      init();

    }  
  });

})(jQuery);

