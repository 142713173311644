function __init_tooggle_save_story() {

	$('.tooggle_save_story').on('click', function(e) {
		e.preventDefault();
		e.stopPropagation();
		var self = $(this);
		var story_id = self.data('id');
		var content_type = self.data('content_type');
		self.find('i').attr('class', 'fa fa-spinner fa-pulse');
		self.find('span.lb').text('កំពុងផ្ទុក..');
		var is_kill = $(this).hasClass('kill');

		var jqxhr = $.post( DOMAIN+"/ajax/tooggle_save_story/"+story_id+"/"+content_type, function( res ) {
			if(res.status) { 
		  	switch(res.action) {
				    case "save":
								self.find('i').attr('class', 'glyphicon glyphicon-floppy-saved');
								self.find('span.lb').text('បានរក្សាទុករួចរាល់');

								// Piwik
						    if (typeof _paq != "undefined") {
						      _paq.push(['trackEvent', "User action", "added library", story_id]);
						    }

				        break;
				    case "remove":
				    		if (is_kill) {
				    			self.closest('.item').fadeOut().remove();
				    		} else {
					        self.find('i').attr('class', 'glyphicon glyphicon-floppy-disk');
									self.find('span.lb').text('ដកចេញរួចរាល់');
				    		}

				    		// Piwik
						    // if (typeof _paq != "undefined") {
						    //   _paq.push(['trackEvent', "User action", "removed library", story_id]);
						    // }

				        break;
				} 
		  }
		}).fail(function() {
	    self.find('i').attr('class', 'glyphicon glyphicon-floppy-disk');
			self.find('span.lb').text('រក្សាទុក');
	  });

	});

}

$(document).ready(function(){
	__init_tooggle_save_story();
});

