jQuery.fn.extend({
    loading: function (background) {

      var bg_class = "";
      if(typeof background != "undefined" && !background) {
        bg_class = "no_background";
      }

      $(this).append('<div class="loading-content '+bg_class+'"><i class="fa fa-spinner fa-pulse fa-3x fa-fw margin-bottom"></i></div>');
      return;
    }, 
    stop_loading: function () {
      $(this).find('.loading-content').remove();
      return;
    }
});

$(document).ajaxError(function( event, jqxhr, settings, thrownError ) {

  if (thrownError == "Unauthorized") {
    // alert("Please login first!");
    popup_login(jqxhr, settings);
  }
});

$(document).ready(function(e) {
  load_more_posts();
  __refresh_credit_balance();

  $('.stories_list').ContentPhotoSwipe();
});

$(window).scroll(function(){
  load_more_posts();
});


function __is_mobile() {
  return ( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) );
}

function __refresh_credit_balance() {

  if (! IS_AUTH_USER) {
    return;
  }

  var credit_balance = $('#credit_balance');  

  if (credit_balance.length <= 0) {
    return;
  }

  $.get( DOMAIN+"/ajax/get_user_credit_balance", function( res ) {
    if (res.status) {   
      credit_balance.text(res.credit); 
    } else {
      console.log("Error user credit balance");
    }
  });

}

function __update_header_html() {
  $.get( DOMAIN+"/ajax/get_header_html", function( res ) {
    if ( res.status && $('#main_header').length ) {   
      $('#main_header').html(res.html);
    } else {
      console.log("Error __update_header_html");
    }
  });
}

window.is_loading = false;
window.current_page = 1;

function load_more_posts()
{
  var loading = $('#loading_more_post');  
  if(typeof loading.offset() != "undefined") {
    if(($(this).scrollTop() + $(window).height() + 200) >= loading.offset().top && !window.is_loading) {
        window.is_loading = true;
        window.current_page += 1;
        var page = window.current_page;
        var display_ele_id = loading.attr('rel');
        var href = window.location.href;
        href = href.replace("#", "");
        loading.find('i').show();
        
        var operator = "?";
        if (href.indexOf("?") >= 0) {
          operator = "&";
        }

        url = href + operator +"page=" + page;

        $.ajax({
          url: url, 
          success: function(result){
            loading.find('i').hide();         
            if(result != "") {
              
              html = $.parseHTML( result );

            	$('#'+display_ele_id).append(html);

              $(html).ContentPhotoSwipe();

              window.is_loading = false;
            }
          }
        });
    }
  }
}


$('.btn-fbShare').on('click', function(e) {
  e.preventDefault();
  e.stopPropagation();

  var link = $(this).data('link');
  
  FB__PublishStory(link, $(this), __story_share_callback);

});


function __story_share_callback(element, response) {

  // console.log(response);

  if (typeof response == "undefined" || 
    typeof response.error_code != "undefined" || 
    ! IS_AUTH_USER) {

    return;
  }

  if (typeof response.post_id != "undefined") {
    fb_og_id = response.post_id;
  } else {
    fb_og_id = "NO_FB_OG_ID";
  }

  var type = element.data('type');
  var story_id = element.data('story_id');
  var picture = element.data('picture');
  var link = element.data('link');

  // Piwik
  if (typeof _paq != "undefined") {
    _paq.push(['trackEvent', "User action", "shared", story_id]);
  }

  var url = DOMAIN + "/ajax/update_content_sharing";
  $.ajax({
    url: url, 
    method: "POST", 
    data: {
      'fb_og_id': fb_og_id,
      'type': type,
      'story_id': story_id,
      'image_url': picture,
      'sharing_url': link
    },
    success: function(result){
      // console.log(result);
    }
  });
   
}

function __init_slick(ele, nav_id) {
  ele.slick({
    slidesToShow: 6,
    infinite: false,
      slidesToScroll: 6,
    appendArrows: nav_id,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5,
            slidesToScroll: 5
        } 
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
            slidesToScroll: 3
        } 
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 5,
            slidesToScroll: 5
        } 
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
            slidesToScroll: 3
        }
      }
    ]
  });
}

$(document).ready(function(){

  var start_up_ads = getCookie('launch_screen_message');
  start_up_ads = parseInt(start_up_ads);

  count_sua = isNaN(start_up_ads) ? 0 : start_up_ads;

  if(count_sua <= 0)
  {
    __load_launch_screen_message();
  }

  $(document).inactivityTime();
      
});


function __load_launch_screen_message()
{
  var url = DOMAIN + '/ajax/launch_screen_message';
  $.ajax({
    url: url, 
    method: 'GET', 
    success: function(result) {
      if (typeof result.status != "undefined" && result.status && result.data != "") {

        if ($('body').find('#redeem_content').length < 1) {
          $('body').append(__redeem_root_element());
        }
        var detail_el = $('#redeem_content');
        detail_el.fadeIn();

        detail_el.css({'overflow' : 'auto'});

        var body_el = detail_el.find('.detail_body');
        body_el.html(result.data);

        // detail_el.find('.blocker').on('click', function(e) {
        //   e.preventDefault();
        //   __close(detail_el);
        // });

        // body_el.find('.btn-close').on('click', function(e) {
        //   e.preventDefault();
        //   __close(detail_el);
        // });

        body_el.find('#btn_continue_plan').on('click', function(e) {
          e.preventDefault();
          __close(detail_el);
        });

        body_el.find('#btn_change_plan').on('click', function(e) {
          e.preventDefault();
          e.stopPropagation();
          __request_change_subscription();

        });

        setCookie("launch_screen_message", 1, get_tomorrow ());

      }
    }
  });

}


$('.btn_sync_data').on('click', function(e) {
  e.preventDefault();
  __refresh_user();
});


