
function do_rating_episode(ele, content_type, story_id, episode_id, type_name) {
    	
	ele.rating({
		showClear: false,
		step: 1,
		clearCaption: "មិនទាន់វាយតម្លៃ",
    starCaptions: {
    	1: 'ខ្ញុំស្អប់រឿងនេះ', 
    	2: 'ខ្ញុំពិតជាមិនចូលចិត្តរឿងនេះ', 
    	3: 'រឿងនេះមិនអីទេ', 
    	4: 'ខ្ញុំចូលចិត្តរឿងនេះ', 
    	5: 'ខ្ញុំស្រលាញរឿងនេះ'
    },
    starCaptionClasses: {1: 'text-danger', 2: 'text-warning', 3: 'text-info', 4: 'text-primary', 5: 'text-success'}
	});

	ele.on('rating.change', function(event, value, caption) {
		var parent = ele.parent();
		parent.html('<i class="fa fa-spinner fa-pulse fa-fw margin-bottom"></i>');
    
    var url = DOMAIN + "/ajax/rating_episode";
    $.ajax({
      url: url, 
      method: "POST", 
      data: {
        'content_type': content_type,
        'story_id': story_id,
        'episode_id': episode_id,
        'type_name': type_name,
        'rating_value': value
      },
      success: function(result){
        // console.log(result);
        if(typeof result.status != 'undefined' && result.status) {

          // Piwik
          if (typeof _paq != "undefined") {
            _paq.push(['trackEvent', "User action", "rated", story_id +"/"+episode_id, value]);
          }

        	msg = '<div class="s-title text-success">អរគុណសម្រាប់ការវាយតម្លៃ</div>';
        } else {
        	msg = '<div class="s-title text-danger">ការវាយតម្លៃបរាជ័យ</div>';
        }

      	parent.html(msg);

      }
    });
  });

}
