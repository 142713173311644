
jQuery.fn.extend({
  
  EpisodeDetail: function () {
   	var old_url = window.location.href;
    var that = $(this);

   	that.find('.episode-item a.btn_episode_detail_qk').on('click', function(e) {
      e.preventDefault();
      
      that.find('.episode-item a.btn_episode_detail').trigger('click');
    });

    that.find('.episode-item a.btn_episode_detail').on('click', function(e) {

   		e.preventDefault();

   		var episode_el = $(this);
   		
   		var type = episode_el.data('type');

      var url = $(this).attr('href');

      var story_id = that.data('story_id');
      var story_type = that.data('story_type');

      var detail_el = __load_detail_el(type);

      detail_el.fadeIn(); 

      detail_el.find('.blocker').on('click', function(e) {
        e.preventDefault();
        __close_episode_detail($(this));
      });
      
			__load_episode_data(type, url, detail_el);			
			
		});


    // press key next, prev
    $(document).keydown(function(e) {
      // e.preventDefault();
      // if has no detail element do nothing
      if ($('body').find('#episode_detail').length) {

        switch (e.keyCode) {
          case 37:
            // prev
            $('.pagination #prev').trigger('click');
            break;

          case 39:
            // next
            $('.pagination #next').trigger('click');
            break;

          case 27:
            // close
            __close_episode_detail($('body').find('#episode_detail'));
            break;
        }
      }

    });


    return;
  },

  QuoteSelector: function () {
  	if (!window.x) {
		    x = {};
		}

		var master = $(this);

		master.parents('#episode__detail').append(__quote_selector_tool());

		var tool_el = master.parents('#episode__detail').find('#quote_selector_tool');
		var selectedText = "";

    var event = "mouseup";

    if (__is_mobile()) {
      event = "touchend";
    }


    master.bind( event , function(e) {

       var 	s = window.getSelection();
       selectedText = s.toString();

	      if (selectedText.length > 0) {

          var content = master.children('.page:visible').not('.end_of_page');

          if (!content.length) {
            return;
          }

          var page = master.children('.page').index(content) + 1;
          var pages_array = window.pages_array;

      		var r = s.getRangeAt(0);
	      	var p = r.getBoundingClientRect();

          startInPageIndex = getSelectionCharacterOffsetStart(content[0]);

          start_index = pages_array[page].start + startInPageIndex;
          end_index = start_index + (selectedText.length - 1);

          // console.log("page: "+page, pages_array[page]);
          // console.log("start",start_index);
          // console.log("end",end_index);

      		if (p.left || p.top) {	

            var top = p.top - tool_el.height() - 10;

            if (__is_mobile()) {
              top = p.top + tool_el.height() + 10;
            }

		        tool_el.css({
			          left: (p.left + (p.width / 2)) - (tool_el.width() / 2),
			          top: top

			      }).fadeIn(200);

		      } else {
		      	tool_el.fadeOut(200);
		      }

	      } else {
          if(tool_el.is(":visible")) {
            tool_el.fadeOut(200);
          }
        }

    });

    tool_el.find('li').bind("click", function(e) {

    	if (selectedText == '') {
    		return;
    	}

      var story_id = master.data("story_id");
    	var episode_id = master.data("episode_id");

    	create_quote_image(story_id, episode_id, selectedText, start_index, end_index);
    });

  }


});

function __load_detail_el(type) {
  var detail_el;

  if (type == 'audio') {
        
    if ($('body').find('#episode__detail__audio').length < 1) {
      $('body').append(detail_root_element_audio());
    }
    detail_el = $('#episode__detail__audio');

  } else {    

    if ($('body').find('#episode__detail').length < 1) {
      $('body').append(detail_root_element());
    }
    detail_el = $('#episode__detail');
  }

  return detail_el;
}

function getSelectionCharacterOffsetStart(element) {
  var start = 0;
  var doc = element.ownerDocument || element.document;
  var win = doc.defaultView || doc.parentWindow;
  var sel;

  if (typeof win.getSelection != "undefined") {
      sel = win.getSelection();
      if (sel.rangeCount > 0) {
          var range = win.getSelection().getRangeAt(0);
          var preCaretRange = range.cloneRange();
          preCaretRange.selectNodeContents(element);
          preCaretRange.setEnd(range.startContainer, range.startOffset);
          start = preCaretRange.toString().length;
      }

  } else if ( (sel = doc.selection) && sel.type != "Control") {
      var textRange = sel.createRange();
      var preCaretTextRange = doc.body.createTextRange();
      preCaretTextRange.moveToElementText(element);
      preCaretTextRange.setEndPoint("EndToStart", textRange);
      start = preCaretTextRange.text.length;
  }

  return start;
}


function getSelectionCharacterOffsetWithin(element) {

    var start = 0;
    var end = 0;
    var doc = element.ownerDocument || element.document;
    var win = doc.defaultView || doc.parentWindow;
    var sel;

    if (typeof win.getSelection != "undefined") {
        sel = win.getSelection();
        if (sel.rangeCount > 0) {
            var range = win.getSelection().getRangeAt(0);
            var preCaretRange = range.cloneRange();

            preCaretRange.selectNodeContents(element);

            preCaretRange.setEnd(range.startContainer, range.startOffset);

            // start = __strip_html_tags(preCaretRange.toString()).length;
            start = preCaretRange.toString().length;

            preCaretRange.setEnd(range.endContainer, range.endOffset);
            end = preCaretRange.toString().length;
        }

    } else if ( (sel = doc.selection) && sel.type != "Control") {
        var textRange = sel.createRange();
        var preCaretTextRange = doc.body.createTextRange();

        preCaretTextRange.moveToElementText(element);

        preCaretTextRange.setEndPoint("EndToStart", textRange);
        start = preCaretTextRange.text.length;

        preCaretTextRange.setEndPoint("EndToEnd", textRange);
        end = preCaretTextRange.text.length;
    }

    return { start: start, end: end };
}

function __strip_html_tags(string)
{
   var tmp = document.createElement("DIV");
   tmp.innerHTML = string;
   return tmp.textContent || tmp.innerText || "";
}

function __set_statistic_reading(episode_id) {

  var ele = $('#episodes').find('.btn_episode_detail[data-episode_id='+episode_id+']');
  if (typeof ele == "undefined") {
    return;
  }
  var story_id    = ele.data('story_id');
  var type_name   = ele.data('type');
  var category_id = ele.data('category_id');

  url = DOMAIN + '/ajax/set_statistic_reading';
  $.ajax({
    url: url, 
    method: "POST", 
    data: {
      'episode_id' : episode_id,
      'story_id' : story_id,
      'type_name' : type_name,
      'category_id' : category_id
    }, 
    success: function(result){

    }
  });

}

function __close_episode_detail(ele) {

	detail_el = ele.parents('.episode__detail');

	if (detail_el.length) {

    var episode_detail = detail_el.find('#episode_detail');
    var total_index = episode_detail.data('total_index');
    var reading_index = episode_detail.data('reading_index');
    var reading_percent = (reading_index * 100) / total_index;

		if (typeof window.ap != "undefined") {
      episode_detail.data('reading_index', Math.round(window.ap.audio.currentTime));
      window.ap.pause();
		} else {
      var reading_speed = episode_detail.data('reading_speed');
      
      if (typeof reading_speed != "undefined" && reading_speed > 0 ) {
        
        if (reading_percent > 20) {
          __update_user_reading_speed(reading_speed);
        }
      }

		}

    if (episode_detail.data('reading_index') > 20) {
      __setViewedIndex(episode_detail);
    }
    
    var type = episode_detail.data('type');
    var story_id = episode_detail.data('story_id');

    var old_url = DOMAIN + "/" + type + "/" + story_id + "/detail";

    //window.history.pushState({path:old_url}, '', old_url);

    // piwik track
    if (typeof _paq != "undefined") {
      _paq.push(['setCustomUrl', old_url]);
    }

		detail_el.fadeOut();
		detail_el.find('.detail_body').html('');
		detail_el.remove();

    $('body').css({ 'overflow' : 'auto' });
	}
}


function __update_user_reading_speed(speed) {
  url = DOMAIN + '/ajax/update_user_reading_speed';
  $.ajax({
    url: url, 
    method: "PUT", 
    data: {
      'speed' : speed
    }, 
    success: function(result){

    }
  });
}

function __load_episode_data(type, url, detail_el) {

  // piwik track
  if (typeof _paq != "undefined") {
    _paq.push(['setCustomUrl', url]);
  }

  detail_el.children('.detail_body').loading();

	ajax_url = url + '?ajax=true';

	$.ajax({
    url: ajax_url, 
    method: "GET", 
    beforeSend: function() {

      if (! $('body').find('.episode__detail').length) {
        detail_el = __load_detail_el(type);
        detail_el.fadeIn();
        detail_el.children('.detail_body').loading();

        detail_el.find('.blocker').on('click', function(e) {
          e.preventDefault();
          __close_episode_detail($(this));
        });
      }
      
    },
    success: function(result) {

    	if (typeof result.status != "undefined" && result.status) {
       
          window.history.pushState({path:url},'',url);
        

        $('body').css({ 'overflow' : 'hidden' });

    		if (type == 'audio') {
	    		display_episode_detail_audio(detail_el, result.view);
	    	}
	    	else {

		    	display_episode_detail(detail_el, result.view);
		    }

	    	$('.btn-close').on('click', function(e) {
					e.preventDefault();
					__close_episode_detail($(this));
				});

        __refresh_credit_balance();
    	
    	} else {
       
    		if (detail_el.hasClass('episode__detail')) {
    			detail_el.addClass('episode__detail__audio')
    								.css({'display':'block'});
    		}

    		display_no_permission(detail_el, result.view);

    	}

    }

  }).fail(function() {

    detail_el.remove();

  });

}


function __quote_selector_tool() {
  var mb_class = __is_mobile() ? "quote_selector_tool_mobile" : "";

	return  '<div class="quote_selector_tool '+mb_class+'" id="quote_selector_tool">' +
						'<ul>' +
							'<li><i class="fa fa-share-alt"></i> ចែករំលែក</li>' +
						'</ul>' +
						'<div class="caret"></div>' +
					'</div>';
}


function display_episode_detail(detail_el, result) {

  var body_el = detail_el.find('.detail_body');
  body_el.html(result);

  var episode_detail = body_el.find('#episode_detail');
	
	var title_h = body_el.find('.title').height();
	var height = body_el.height() - (50 + title_h);

  if (window.innerWidth <= 767) {
    height += 26;
  }

	episode_detail.css({'height': height+'px'});

	episode_detail.MyPagination({
										height: height, 
										fadeSpeed: 400
									});

  __init_bright_bar();
  __init_font_bar();
  __init_screen_mode();
  __toolbar_handler();
  
  if ((episode_detail.data('reading_index') > 20 && IS_AUTH_USER) || IS_AUTH_USER) {
    __set_statistic_reading(episode_detail.data('episode_id')); 
  }
}

function __toolbar_handler()
{
  if(!__is_mobile() ||
    (typeof $('#episode_detail_tools') == "undefined") ||
    (typeof $('#book_episode_content') == "undefined") ||
    window.innerWidth >= 700)
  {
    return;
  }

  $('#book_episode_content').on('click', function() {
    $('#episode_detail_tools').fadeToggle();
    $(this).find('.title span').fadeToggle();
  });
  
  
}

function __init_bright_bar()
{
  var brightSlider = $('#brightBar').slider({
    formatter: function(value) {
      return 'កម្រិតពន្លឺ: ' + value + "%";
    }
  });

  $('.episode__detail .detail_body').css({'background':'#000'});

  var cookie_name = "sbeno_bright_content";
  var bright = getCookie(cookie_name);

  if (bright == "") {
    bright = 90;
  }

  __set_bright_content(bright);

  brightSlider.slider('setValue', bright);

  brightSlider.on('change', function(e) {
    var bright = e.value.newValue;
    __set_bright_content(bright);

    setCookie(cookie_name, bright, 365);
  });

  $('#brightSlider').on('click', function(e) {
    e.stopPropagation();
  });
}

function __set_bright_content(bright) {
  var ele = $('.episode__detail .detail_body #book_episode_content');
  if (typeof ele == "undefined") {
    return;
  }

  opacity = (bright / 100).toString();

  ele.css({
    'opacity' : opacity
  });

}

function __init_font_bar()
{
  var fontSlider = $('#fontBar').slider({
    formatter: function(value) {
      return 'ទំហំអក្សរ: ' + value;
    }
  });

  var cookie_name = "sbeno_font_size";
  var font_size = getCookie(cookie_name);

  if (font_size == "") {
    font_size = 14;
  }
  
  __set_font_size_content(font_size);

  fontSlider.slider('setValue', font_size);

  fontSlider.on('slideStop', function(e) {
    var font_size = e.value;
    __set_font_size_content(font_size);
    setCookie(cookie_name, font_size, 365);

    var episode_detail = $('#book_episode_content .episode_detail');

    episode_detail.MyPagination({
                    height: episode_detail.height(), 
                    fadeSpeed: 400
                  });
  });

  $('#fontSlider').on('click', function(e) {
    e.stopPropagation();
  });
}

function __set_font_size_content(font_size) {
  var ele = $('.episode__detail .detail_body #book_episode_content .episode_detail');
  if (typeof ele == "undefined") {
    return;
  }

  ele.css({
    'font-size' : font_size.toString() + 'px',
    'line-height' : (parseInt(font_size) + 16).toString() + 'px'
  });


}

function __init_screen_mode()
{
  var cookie_name = "sbeno_screen_night_mode";
  var night_mode = getCookie(cookie_name);

  if (night_mode == "true") {
    __set_night_mode();
  } else {
    __set_day_mode();
  }

  $('#day_night_mode .btn-group button').on('click', function(e) {
    e.stopPropagation();

    var mode = $(this).data('mode');
    if (mode == "night") {
      __set_night_mode();
      setCookie(cookie_name, true, 365);
    } else {
      __set_day_mode();
      setCookie(cookie_name, false, 365);
    }

  });
}

function __set_night_mode() {
  $('#day_night_mode .btn-group button').removeClass('active');
  $('#day_night_mode .btn-group button.night').addClass('active');

  var ele = $('.episode__detail .detail_body #book_episode_content');
  if (typeof ele == "undefined") {
    return;
  }

  ele.removeClass('day');
  ele.addClass('night');
}

function __set_day_mode() {
  $('#day_night_mode .btn-group button').removeClass('active');
  $('#day_night_mode .btn-group button.day').addClass('active');

  var ele = $('.episode__detail .detail_body #book_episode_content');
  if (typeof ele == "undefined") {
    return;
  }

  ele.removeClass('night');
  ele.addClass('day');
}


function display_episode_detail_audio(detail_el, result) {
  // piwik track
  if (typeof _paq != "undefined") {
    _paq.push(['trackPageView']);
  }


	var body_el = detail_el.find('.detail_body');
	body_el.html(result);

	var episode_detail = body_el.find('#episode_detail');
  var ele_id = 'episode_detail_audio';

	var url = episode_detail.find('#'+ele_id).text();
	url = $.trim(url);

	if (__isUrl(url)) {
    var title = body_el.find('h2.title').text();
		audio_player(episode_detail, ele_id, title, url);
	}

  __handle_next_prev_audio(episode_detail);

  __handle_share_activity(episode_detail);

  var ele = episode_detail.find('#star_rating_episode');

  var content_type = episode_detail.data('content_type');
  var story_id = episode_detail.data('story_id');
  var episode_id = episode_detail.data('episode_id');

  do_rating_episode(ele, content_type, story_id, episode_id, content_type);
  __set_statistic_reading(episode_detail.data('episode_id')); 
}


function __handle_next_prev_audio(episode_detail) {
  var episode_id = episode_detail.data('episode_id');

  var episodes_obj = $('#episodes');
  var curent_episode = episodes_obj.find('.episode-item[data-episode_id='+ episode_id +']');

  if (typeof curent_episode != "undefined") {
    var next_episode = curent_episode.next('.episode-item');
    var prev_episode = curent_episode.prev('.episode-item');

    if (next_episode.length) {
      next_url = next_episode.children('.btn_episode_detail').attr('href');
      next_type = next_episode.children('.btn_episode_detail').data('type');

      episode_detail.find('.btn_ep_next_prev.next')
                    .attr('href', next_url)
                    .data('type', next_type)
                    .removeAttr('disabled');
    } 

    if (prev_episode.length) {
      prev_url = prev_episode.children('.btn_episode_detail').attr('href');
      prev_type = prev_episode.children('.btn_episode_detail').data('type');

      episode_detail.find('.btn_ep_next_prev.prev')
                    .attr('href', prev_url)
                    .data('type', prev_type)
                    .removeAttr('disabled');
    }

  }

  episode_detail.find('.btn_ep_next_prev').on('click', function(e) {
      e.preventDefault();

      if (typeof $(this).attr('disabled') != "undefined" ) {
        return false;
      }

      var type = $(this).data('type');
      var url = $(this).attr('href');
      detail_el = $(this).parents('.episode__detail');

      if (typeof window.ap != "undefined") {
        episode_detail.data('reading_index', Math.round(window.ap.audio.currentTime));
        window.ap.pause();
        __setViewedIndex(episode_detail);
      }

      __load_episode_data(type, url, detail_el);
    });

}


function display_no_permission(detail_el, result) {

	var body_el = detail_el.find('.detail_body');
  body_el.html(result);
  
  detail_el.find('#btn_change_plan').on('click', function(e) {
      e.preventDefault();
      e.stopPropagation();
      $(".auto_message").hide();
      if ($('.episode__detail__audio').length) {
        
        $('#episode__detail').toggleClass('episode__detail__audio subscription');
      }
      else {
        $('#episode__detail').toggleClass('subscription episode__detail__audio');
      }
      __request_change_subscription();
  });

  // detail_el.find('.blocker').on('click', function(e) {
  //   e.preventDefault();
  //   __close_episode_detail($(this));
  // });
}

function __setViewedIndex(objContent) {

  if (! objContent.length) {
    console.log("__setViewedIndex: no objContent!");
    return;
  }

  var story_id = objContent.data('story_id');
  var total_index = objContent.data('total_index');
  var episode_id = objContent.data('episode_id');
  var episode_order = objContent.data('order');
  var content_type = objContent.data('content_type');
  var reading_index = objContent.data('reading_index');

  var story_el = $('#episodes');
  var episode_el = story_el.find('.episode-item[data-episode_id='+episode_id+']');

  if (episode_el.find('.progress').length < 1) {
    episode_el.find('.clear').before('<div class="progress">'+
      '<div class="progress-bar" role="progressbar" style="width:1%;">'+
      '</div>'+
    '</div>');
  }

	if (total_index > 0 && reading_index > 0) {
		var reading_percent = (reading_index * 100) / total_index;
		// console.log(reading_percent);
		if (reading_percent > 0) {
			episode_el.find('.progress-bar').css({'width': reading_percent +'%'});
		}
	} else if (reading_index == 0) {
		episode_el.find('.progress-bar').css({'width': '2%'});
	}

  var url =  DOMAIN + "/ajax/update_reading";
  var reading_order =  episode_el.index() + 1;

  var story_flag_completed = story_el.data('flag_completed');
  var story_total = story_el.data('total');
  if (reading_percent > 20) {
    $.ajax({
      url: url, 
      data: {
        'story_id': story_id,
        'episode_id': episode_id,
        'content_type': content_type,
        'total_index': total_index,
        'reading_index': reading_index,
        'reading_order': reading_order,
        'story_flag_completed': story_flag_completed,
        'story_total': story_total
      },
      method: "PUT", 
      success: function(result) {

        $('#btn_read_last_episode').data('reading_order', reading_order);

        // Piwik
        if (typeof _paq != "undefined" && 
            typeof result.new_story != "undefined" && 
            result.new_story) {
            _paq.push(['trackEvent', "User action", "added library", story_id]);
        }
      }
    });
  }


}

function __dispaly_bookmark_button(objContent) {

  start_index = objContent.data('reading_index');
  end_index = objContent.data('end_page_index');

  var bookmarks = objContent.data('bookmarks');
  objContent.parents('.detail_body').find('.btn-bookmark').removeClass('booked');

  for (i = 0; i < bookmarks.length; i++) {
  	if(bookmarks[i].index >= start_index 
  		&& bookmarks[i].index <= end_index) {
  		objContent.parents('.detail_body').find('.btn-bookmark').addClass('booked');
  	}
  }
  
}

function __setBookmarkIndex(objContent) {

  var totalIndex = objContent.text().length;
  var story_id = objContent.data('story_id');
  var episode_id = objContent.data('episode_id');
  var content_type = objContent.data('content_type');
  var reading_index = objContent.data('reading_index');
  var end_page_index = objContent.data('end_page_index');

  objContent.parents('.detail_body').find('.btn-bookmark').html('<i class="fa fa-spinner fa-spin"></i>');

  var url =  DOMAIN + "/ajax/bookmark";
  $.ajax({
    url: url, 
    data: {
      'story_id': story_id,
      'episode_id': episode_id,
      'content_type': content_type,
      'total_index': totalIndex,
      'reading_index': reading_index,
      'end_page_index': end_page_index
    },
    method: "POST", 
    success: function(result) {

      objContent.parents('.detail_body').find('.btn-bookmark').html('<i class="glyphicon glyphicon-bookmark"></i>');

    	if (typeof result.status != "undefined" && result.status) {

        var bookmarks = objContent.data('bookmarks');

        if (result.removed) {

          bookmarks.forEach(function(bookmark, key) {
            if (end_page_index > 0 && 
              bookmark.index >= reading_index && 
              bookmark.index <= end_page_index) {

              bookmarks.splice(key, 1);

            } else if (bookmark.index == reading_index) {

              bookmarks.splice(key, 1);

            }
          });

        } else {
          bookmarks.push(result.new_bookmark);
        }

        objContent.data('bookmarks', bookmarks);

        __write_bookmark_list(objContent);

        __dispaly_bookmark_button(objContent);

        __init_bookmark_actions(objContent);

    	}
    }
  });
}

function __init_bookmark_actions(objContent) {
  // Click on list of bookmark to show page of bookmark
  objContent.parents('.detail_body').find('#bookmark_list a').on('click', function(e) {
    var book_page = $(this).data('page');
    var index = $(this).data('index');
    showPage(book_page, index);
  });

  // Click on button trash in bookmark dropdown list
  objContent.parents('.detail_body').find('#bookmark_list .trash').on('click', function(e) {
    e.stopPropagation();
    var index = $(this).data('index');
    var ex_reading_index = objContent.data('reading_index');
    var ex_end_page_index = objContent.data('end_page_index');

    objContent.data('reading_index', index);
    objContent.data('end_page_index', 0);

    __setBookmarkIndex(objContent);

    objContent.data('reading_index', ex_reading_index);
    objContent.data('end_page_index', ex_end_page_index);
  });
}

function __isUrl(s) {
   var regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
   return regexp.test(s);
}

function audio_player(episode_detail, ele, title, url) {
  
	window.ap = new APlayer({
	    element: document.getElementById(ele),
	    narrow: false,
	    autoplay: true,
	    showlrc: false,
	    mutex: true,
	    theme: '#0E9FD0',
	    preload: 'auto',
	    mode: 'order',
	    music: { 				       // Required, music info, see: ###With playlist
          title: title,      // Required, music title
          author: "",    // Required
	        url: url           // Required, music url
	    }
  });
  
  if ($("#tvc_feature_enable").val() == true) {
    window.ap.pause();
  }

  window.ap.audio.onloadstart = function() {
    if (!__is_mobile()) {
      episode_detail.find('.aplayer-pic .aplayer-icon')
                .html('<i class="fa fa-spinner fa-pulse fa-fw margin-bottom"></i>');
    }
  };

  window.ap.audio.onloadedmetadata = function() {
    var reading_index = episode_detail.data('reading_index');
    window.ap.audio.currentTime = reading_index;

    episode_detail.data('total_index', Math.round(window.ap.audio.duration));
    __setViewedIndex(episode_detail);
  };

  // re init inactivityTime
  $(document).inactivityTime();
  
}

function detail_root_element() {
	return '<div id="episode__detail" class="episode__detail">'+
					'<div class="blocker"></div>'+
					'<div class="detail_body">'+
					'</div>'+
				 '</div>';
}

function detail_root_element_audio() {
	return '<div id="episode__detail__audio" class="episode__detail episode__detail__audio">'+
					'<div class="blocker"></div>'+
					'<div class="detail_body">'+
					'</div>'+
				 '</div>';
}

function create_quote_image(story_id, episode_id, text, start_index, end_index) {
  if ($('body').find('#quote__generator').length < 1) {
    $('body').append(quote_generator_root_element());
  }
  var detail_el = $('#quote__generator');
  detail_el.fadeIn();
  detail_el.find('.detail_body').loading();

	var url = DOMAIN + "/ajax/quote_generator";
	$.ajax({
    url: url, 
    data: {
    	'story_id': story_id
    },
    success: function(result){
      
      if(typeof result.errors != "undefined") {
        __close($('#quote__generator'));
        return;
      }

      display_quote_generator(detail_el, story_id, result, text, episode_id, start_index, end_index);
    }
  });
}


function display_quote_generator(detail_el, story_id, html, text, episode_id, start_index, end_index) {
	
  detail_el.find('.detail_body').html(html);

  var bg = detail_el.find("#background_selector li.active");      

			bg_url = bg.css('background-image').replace('url(','').replace(')','').replace(/\"/gi, "");

	var quote = detail_el.find('.detail_body .quote');
	var story = quote.data('story');
	var author = quote.data('author');

	__preview_canvas(bg_url, text, story, author);

 	__background_selector(detail_el, text, story, author);

  detail_el.find(".btn-close").on('click', function(e) {
    __close($('#quote__generator'));
  });

  detail_el.find("#btn_share_quote").on('click', function(e) {

		e.preventDefault();

		detail_el.find(".detail_body .quote").loading();

    background_id = detail_el.find("#background_selector li.active").data('id');

		var canvas = document.getElementById('canvas');

    var image_type = "png";
    base64Img = canvas.toDataURL("image/" + image_type, 1);

    var url = DOMAIN + "/ajax/sharing_story_quote";
		$.ajax({
	    url: url, 
	    method: "POST", 
	    data: {
	    	'story_id': story_id,
        'image_type': image_type,
        'image': base64Img,
        'episode_id': episode_id,
        'start_index': start_index,
        'end_index': end_index,
        'background_id': background_id,
        'text': text
	    },
	    success: function(result) {

	    	if(typeof result.status != "undefined" && result.status) {
	    		var data = result.sharing_data;
	    		var share_log_id = data.id;

		    	var title = "ក្នុងរឿង៖ "+ story;
				  var caption = DOMAIN;
				  var description = "អ្នកនិពន្ធ៖ "+ author;
				  var link = data.sharing_url;
          var picture = data.image_url;

				  element = {
				  	'story_id':story_id,
				  	'share_log_id':share_log_id,
				  	'image_url':picture,
				  	'sharing_url':link,
            'episode_id': episode_id,
            'start_index': start_index,
            'end_index': end_index,
            'background_id': background_id,
            'image_type': image_type
				  }

		      FB__PublishStory(link, element, __quote_share_callback);

          detail_el.find(".detail_body .quote").stop_loading();

		    } else {

          detail_el.find(".detail_body .quote")
                  .html('<h4 class="text-danger text-center">ចែករំលែកបរាជ័យ</h4>');
        }

	    }
	  });

	});	

}

function __quote_share_callback(element, response) {

  if (typeof response.post_id != "undefined") {

    var story_id		 	= element.story_id;
    var share_log_id 	= element.share_log_id;
    var image_url 		= element.image_url;
    var sharing_url   = element.sharing_url;
    var episode_id    = element.episode_id;
    var start_index   = element.start_index;
    var end_index     = element.end_index;
    var background_id = element.background_id;
    var image_type 	  = element.image_type;

    var url = DOMAIN + "/ajax/update_sharing_story_quote";
    $.ajax({
      url: url, 
      method: "POST", 
      data: {
        'fb_og_id': response.post_id,
        'share_log_id': share_log_id,
        'story_id': story_id,
        'image_url': image_url,
        'sharing_url': sharing_url,
        'episode_id': episode_id,
        'start_index': start_index,
        'end_index': end_index,
        'background_id': background_id,
        'image_type': image_type
      },
      success: function(result){
        // console.log(result);
        __close($('#quote__generator'));
      }
    });
  } else {
    console.log(response);
  }
}


function __preview_canvas(bg_url, text, story, author) {

	var canvas = document.getElementById('canvas');
  
  var size = 1024;
  canvas.width = size;
  canvas.height = size;

  var ctx = canvas.getContext("2d");
  var img = new Image();
  img.crossOrigin = "Anonymous";
  img.src = bg_url;
  
  img.onload = function(){
    // ctx.drawImage(img, 0, 0, width, height);
    ctx.drawImage(this, 0, 0, size, size);

    // set shadow color, offset, blur
    ctx.shadowColor = "rgba(0, 0, 0, 1)";
    ctx.shadowOffsetX = 2; 
    ctx.shadowOffsetY = 2; 
    ctx.shadowBlur = 5;

    ctx.font = '56px KhmerOSKoulen';
    ctx.fillStyle = '#ffffff';
    ctx.textAlign = "center";
    ctx.textBaseline = 'top';


    var maxWidth = size - 100,
    		lineHeight = 96,
    		x = size / 2, 
    		y = size / 2;
    

    var lines = __wrapTextCanvas(ctx, text, maxWidth);

    y -= (lines.length / 2) * lineHeight ;
	  for(var n = 0; n < lines.length; n++) {
	    ctx.fillText(lines[n], x, y);
	    y += lineHeight;
	  }

	  ctx.font = '27px KhmerOSKoulen';
    ctx.textAlign = "left";
    ctx.shadowOffsetX = 0; 
    ctx.shadowOffsetY = 0; 
    ctx.shadowBlur = 0;

    ctx.fillText("ក្នុងរឿង៖ "+ story,  120, (size - 92));
    ctx.fillText("អ្នកនិពន្ធ៖ "+ author,  120, (size - 45));

    var logo = new Image();
		logo.src = DOMAIN + '/img/e-novel-logo-3x.png';
	  logo.onload = function(){
	    ctx.drawImage(this, 20, (size - 100), 82, 82);
	  }

	};

}


function __wrapTextCanvas(ctx, text, maxWidth) {
	  var words = text.split(' '),
      lines = [],
      line = "";

  while (words.length > 0) {
      var split = false;
      while (ctx.measureText(words[0]).width >= maxWidth) {
          var tmp = words[0];
          words[0] = tmp.slice(0, -1);
          if (!split) {
              split = true;
              words.splice(1, 0, tmp.slice(-1));
          } else {
              words[1] = tmp.slice(-1) + words[1];
          }
      }
      if (ctx.measureText(line + words[0]).width < maxWidth) {
          line += words.shift() + " ";
      } else {
          lines.push(line);
          line = "";
      }
      if (words.length === 0) {
          lines.push(line);
      }
  }

  return lines;
}

function _background_slick_init(ele_list, slideIndex, text, story, author) {

  ele_list.slick({
    slidesToShow: 7,
    infinite: false,
    slidesToScroll: 7,
    arrows: false,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 5,
            slidesToScroll: 5
        }
      }
    ]
  })
  .slick('slickGoTo', parseInt(slideIndex));

  __do_background_list_click(ele_list, text, story, author);
}


function __background_selector(detail_el, text, story, author) {
	
  var ele_list = detail_el.find("#background_selector");
  _background_slick_init(ele_list, 0, text, story, author);

  detail_el.find('.blocker').on('click', function(e) {
		e.preventDefault();
		__close(detail_el);
	});	

  detail_el.find("#background_selector li.btn_more_backgrounds").on('click', function(e) {
    var that = $(this);
    var old_text = that.text();
    that.html('<i class="fa fa-spinner fa-pulse fa-fw"></i>');

    var page = that.parents('#background_selector').data('page');
    var url = DOMAIN + '/ajax/quote_backgrounds/' + (page + 1);
    $.get( url, function( data ) {
      
      if (typeof data.status != "undefined" && data.status) {

        var ele_list = that.parents('#background_selector');

        ele_list.data('page', page + 1)

        ele_list.slick('unslick');

        that.before(data.html);

        var slideIndex = (page * 7);

        _background_slick_init(ele_list, slideIndex, text, story, author);

        that.text(old_text);
        var list = data.list;

        if (!data.has_more) {
          that.remove();
        }
      } else {
        that.remove();
      }

    });

  });

}


function __do_background_list_click(background_selector, text, story, author) {
  background_selector.find("li.background_item").on('click', function(e) {
    e.preventDefault();
    background_selector.find("li.background_item").removeClass('active');
    $(this).addClass('active');
    var bg = $(this).css('background-image');
    bg_url = bg.replace('url(','').replace(')','').replace(/\"/gi, "");

    __preview_canvas(bg_url, text, story, author);
  }); 
}


function __close(detail_el) {
	detail_el.fadeOut();
	detail_el.find('.detail_body').html('');
	detail_el.remove();
}


function quote_generator_root_element() {
	return '<div id="quote__generator" class="quote__generator">'+
					'<div class="blocker"></div>'+
					'<div class="detail_body">'+
						'<div class="quote">'+
							'<div class="preview">'+
								'<canvas id="canvas_image"></canvas>'+
							'</div>'+
							'<ul class="list-background">'+
							'</div>'+
						'</div>'+
					'</div>'+
				 '</div>';
}


$(document).ready(function(){

  $('.btn_rating_episode').on('click', function(e) {
    e.preventDefault();
    __rating_episode_enovel($(this));
  });


	$('#btn_read_last_episode').on('click', function(e) {
		e.preventDefault();
    var reading_order = $(this).data('reading_order');
		episode = $('#episodes').find('.episode-item')[reading_order - 1];

    if (typeof episode != "undefined") {
      $(episode).find('a.btn_episode_detail').trigger( "click" );
    }

	});

});


function __rating_episode_enovel(ele) {
  
 	var content_type = ele.data('content_type');
 	var episode_id = ele.data('episode_id');
 	var story_id = ele.data('story_id');
 	var type = ele.data('type');


 	var url = DOMAIN + '/ajax/rating_episode/' + episode_id;

 	$.ajax({
    url: url, 
    method: 'GET', 
    success: function(result) {

        if ($('body').find('#episode__detail__audio').length < 1) {
          $('body').append(detail_root_element_audio());
        }
        
        var detail_el = $('#episode__detail__audio');

        detail_el.fadeIn();

      	var body_el = detail_el.find('.detail_body');
  			body_el.html(result);
      	
      	$('.btn-close').on('click', function(e) {
  				e.preventDefault();
  				__close(detail_el);
  			});	

      	var ele = $('#star_rating_episode');
  			do_rating_episode(ele, content_type, story_id, episode_id, type);

        detail_el.find('.blocker').on('click', function(e) {
          e.preventDefault();
          __close(detail_el);
        });

      }

  });

}


function __handle_share_activity(objContent) {

  objContent.find('#btn_share_activity').on('click', function(e) {
    e.preventDefault();

    var element = $('body').find('.btn-fbShare');

    if (typeof element != "undefined") {

      var url = element.data('link');
      var type = element.data('type');
      var image = element.data('picture');
      var title = element.data('title');
      var description = element.data('description');

      if (type == "audio") {
        
        FB__PublishStory(url, element, __story_share_callback);

      } else {
        action = "books.reads";
        object = { "book" : {
                  "og:type": "books.book",
                  "og:title": title,
                  "og:url": url,
                  "og:image": image,
                  "og:description": description,
                  "books:isbn": "0000"
                }};

        FB__shareActivity(action, object, element, __story_share_callback);
      }

      
    }

    
  });

}

function __write_bookmark_list(objContent) {

  var bookmark_list = objContent.parents('.detail_body').find('.list-bookmarks');
  var bookmarks = objContent.data('bookmarks');
  var objPages = objContent.children('.page');

  if (bookmarks.length > 0) {
    bookmark_list.find('.dropdown-menu').html('');
  } else {
    bookmark_list.find('.dropdown-menu').html('<li><a style="text-align: center; color: #999;">គ្មានការរក្សាទុក</a></li>');
  }

  var pages_array = window.pages_array;

  pages_array.forEach(function(page_data, page) {

    var objPage = $(objPages[page - 1]).clone();

    bookmarks.forEach(function(bookmark, index) {

      if (bookmark.index >= page_data.start && 
        bookmark.index <= page_data.end) {

        var char_lenght = 40;

        var bookmark_page_index = bookmark.index - page_data.start;

        $(objPage).find('.content_ads').remove();

        var char = $(objPage).text().substring(bookmark_page_index, bookmark_page_index + char_lenght) + "..";

        bookmark_list.find('.dropdown-menu').append(__bookmark_list_ele(page, bookmark.date, char, bookmark.index));

      }

    });

  });

  moment_init(bookmark_list);

}


(function($){
    $.fn.extend({ 
        MyPagination: function(options) {
            var defaults = {
                height: 600,
                fadeSpeed: 400,
                tran_page: "page",
                tran_of: "of",
                numPages: 0
            };
            var options = $.extend(defaults, options);
            //Creating a reference to the object
            var objContent = $(this);

            // other inner variables
            var fullPages = new Array();
            var subPages = new Array();
            var height = 0;
            var lastPage = 0;
            var page = 1;
            var lastpageCharacters = 0;
            var paginatePages;
            var showedCharacter = 0;

            var big_ads_page_count = objContent.data('big_ads_page_count');
            var small_ads_page_count = objContent.data('small_ads_page_count');
            var big_ads_html;
            var small_ads_html;
            var big_ads_height = 278;
            // original small ads is 728 x 90
            var small_ads_height = (objContent.width() * 90 / 728);

            var start_read;
            var end_read;
            var reading_speed = 0;

            var reading_index = objContent.data('reading_index');

            // initialization function
            init = function() {
              objContent.parent().loading();
              // hiding all wrapped pages
              objContent.children().hide();

              var url = DOMAIN + '/ajax/episode_ads';

              $.get( url, function( ads ) {

                objContent.parent().stop_loading();

                objContent.children().show();

                big_ads_html = ads.big;
                small_ads_html = ads.small;
                
                var is_ads_display = false;
                var is_small_ads_display = false;
                
                var my_page = 1;
                var total_index = 0;
                var setted_page = false;

                var start_page_indexs = 0;
                var page_text = "";

                window.pages_array = [];

                /*
                  redo it when user resize font
                  so need to remove old generated element first
                */
                objContent.find('div.content_ads').remove();
                objContent.find('div.end_of_page').remove();
                var pTags = objContent.find('p');
                if ( pTags.parent().is( "div.page" ) ) {
                  pTags.unwrap();
                }
                // end

                objContent.children().each(function(i) {

                    var h = $(this).outerHeight();
                    var t = parseInt($(this).css('margin-top'), 10);
                    var b = parseInt($(this).css('margin-bottom'), 10);

                    var child_text = $(this).text();
                    var child_index = child_text.length;

                    var objHeight = h + t + b;

                    // If a page
                    if (height + objHeight > options.height) {

                        // Find curent page
                        if(reading_index >= start_page_indexs 
                          && reading_index < total_index) {

                          page = my_page;

                        }

                        var page_obj = new Object();
                        page_obj.start = start_page_indexs;
                        page_obj.end = total_index - 1;

                        window.pages_array[my_page] = page_obj;                        

                        fullPages.push(subPages);
                        subPages = new Array();
                        height = 0;
                        page_text = "";
                        start_page_indexs += total_index - start_page_indexs;
                        my_page ++;
                        is_ads_display = false;
                        is_small_ads_display = false;
                    }

                    
                    height += objHeight;
                    subPages.push(this);

                    // append ads to content
                    if ( __is_big_ads(big_ads_page_count, my_page) &&  
                      !is_ads_display) {

                      // 15 is for padding
                      height += (big_ads_height + 15);
                      is_ads_display = true;
                    }

                    // append small ads to content space
                    if ( __is_small_ads(small_ads_page_count, big_ads_page_count, my_page) != 0 &&
                      !is_small_ads_display) {

                      // 30 is for padding
                      height += (small_ads_height + 15);
                      is_small_ads_display = true;
                    }
                    
                    total_index += child_index;
                    page_text += child_text;
                  
                });



                // Find curent page in last page
                if ( reading_index >= start_page_indexs 
                  && reading_index < total_index ) {
                  page = my_page;

                } else if (reading_index == total_index) {
                  page = my_page + 1;
                }

                // set the last page
                var page_obj = new Object();
                page_obj.start = start_page_indexs;
                page_obj.end = total_index;
                window.pages_array[my_page] = page_obj;

                // Set total index to detail parent element
                objContent.data('total_index', total_index);

                // console.log(total_index);

                if (height > 0) {
                  fullPages.push(subPages);
                }

                // wrapping each full page
                $(fullPages).wrap("<div class='page'></div>");

                // This is the end of pages
                var image_url = objContent.data('image_url');
                var authored = objContent.data('authored');
                var episode_id = objContent.data('episode_id');
                var content_type = objContent.data('content_type');
                var story_id = objContent.data('story_id');
                var type = objContent.data('type');

                objContent.append(__the_end_of_pages(episode_id, authored, image_url));
                // rating episode
                var ele = $('#star_rating_episode');
                do_rating_episode(ele, content_type, story_id, episode_id, type);


                var page_obj = new Object();
                page_obj.start = total_index;
                page_obj.end = total_index;
                window.pages_array[my_page + 1] = page_obj;

                // hiding all wrapped pages
                objContent.children().hide();

                // console.log(objContent.html());

                // making collection of pages for pagination
                paginatePages = objContent.children();

                // Set total number of pages
                options.numPages = $(paginatePages).length;

                // show first page
                showPage(page);

                __write_bookmark_list(objContent);

                __setViewedIndex(objContent);

                objContent.find('.btn_ep_next_prev').on('click', function(e) {
                  e.preventDefault();
                  var type = $(this).data('type');
                  var url = $(this).attr('href');
                  detail_el = $(this).parents('.episode__detail');

                  __setViewedIndex(objContent);
                  __load_episode_data(type, url, detail_el);
                });

                __handle_share_activity(objContent);

                __init_bookmark_actions(objContent);


                objContent.QuoteSelector();


              });

              // Click on button bookmark to set/remove bookmark
              objContent.parents('.detail_body').find('.btn-bookmark').on('click', function(e) {
                __setBookmarkIndex(objContent);
                  // $(this).toggleClass('booked');
              }); 

              // draw controls
              // showPagination(options.numPages);
            };

            // update counter function
            updateCounter = function(i) {
                $('#page_number').html(i+"/"+options.numPages);
            };

            scrollTop = function() {
                $('html, body').animate({
                    scrollTop: ($(".story-detail-body .page_number").offset().top - 60)
                }, 400);
            };



            __calculate_reading_speed = function() {
                end_read = new Date().getTime();
                var page = $(paginatePages[lastPage - 2]);
                var page_char = page.text().length;

                if (page_char <= 0) {
                  return;
                }

                // convert mili second to second
                var page_time = (end_read - start_read) / 1000;

                char_per_sec = page_char / page_time;


                if (reading_speed > 0) {
                  reading_speed = (reading_speed + char_per_sec) / 2 ;
                } else {
                  reading_speed  = char_per_sec;
                }

                objContent.data('reading_speed', parseInt(reading_speed));
            };


            // show page function
            showPage = function(page, bookmark_index) {
                start_read = new Date().getTime();

                // Hide quote_selector_tool
                if ($('#episode__detail').find('#quote_selector_tool').length) {
                  $('#episode__detail').find('#quote_selector_tool').hide();
                }

                if(page < 2) {
                    $('.pagination #prev').parent().addClass('disabled');
                } else {
                    $('.pagination #prev').parent().removeClass('disabled');
                }

                if(page >= options.numPages) {
                    $('.pagination #next').parent().addClass('disabled');
                } else {
                    $('.pagination #next').parent().removeClass('disabled');
                }

                i = page - 1; 
                j = lastPage - 1; 

                if (typeof paginatePages == 'object' && paginatePages[i]) {

                    // piwik track
                    if (typeof _paq != "undefined") {
                      _paq.push(['trackPageView']);
                    }

                    $(paginatePages[i]).children('.content_ads').remove();;

                    // hiding old page, display new one
                    $(paginatePages[j]).fadeOut(options.fadeSpeed);
                    $(paginatePages[i]).fadeIn(options.fadeSpeed);

                    if (__is_mobile() && 
                      !$(paginatePages[i]).hasClass('end_of_page')) {
                      // swipe content to next prev page
                      __swipe_episode_page($(paginatePages[i]));
                    }

                    objContent.data('reading_index', window.pages_array[page].start);
                    objContent.data('end_page_index', window.pages_array[page].end);
                    
                    // Do highlight charector of bookmark
                    if(typeof bookmark_index != "undefined") {

                      var char_index = bookmark_index - window.pages_array[page].start;

                      var pageChar = $(paginatePages[i]).clone();
                      pageChar.find('.content_ads').remove();

                      var str = pageChar.text().substr(char_index, 15);

                      $(paginatePages[i]).html(function (i, old) {
                        return old.replace(str, '<span class="highlight">' + str + '</span>');
                      });

                    }

                    // append big ads
                    if ( __is_big_ads(big_ads_page_count, page) && 
                      !$(paginatePages[i]).hasClass('end_of_page')) {

                      var content_ads = document.createElement('div');
                      content_ads.className = "content_ads";

                      content_ads.innerHTML = big_ads_html;

                      var page_ads = $(paginatePages[i]);

                      var page_height = page_ads.outerHeight();
                      var content_height = page_ads.contents().outerHeight();

                      if ( content_height + (big_ads_height + 15) <= page_height) {
                        page_ads.append(content_ads);
                      }

                    }

                    // append small ads
                    if (  __is_small_ads(small_ads_page_count, big_ads_page_count, page) &&
                      !$(paginatePages[i]).hasClass('end_of_page')) {

                      var content_ads = document.createElement('div');
                      content_ads.className = "content_ads";
                      content_ads.innerHTML = small_ads_html;

                      var iframe = $(content_ads).find('iframe');
                      iframe.css({'height': small_ads_height+'px'});

                      var page_ads = $(paginatePages[i]);

                      var page_height = page_ads.outerHeight();
                      var content_height = page_ads.contents().outerHeight();

                      if ( content_height + (small_ads_height + 15) <= page_height) {
                        $(paginatePages[i]).append(content_ads);
                      }
                    }

                    // Display related stories
                    // at the end of last episode
                    var episodes_el = $('#episodes');

                    if ( $(paginatePages[i]).hasClass('end_of_page') &&
                      !$('#related_stories').length && 
                      episodes_el.length ) {

                      var story_flag_completed = episodes_el.data('flag_completed');
                      var story_total = episodes_el.data('total');
                      var story_id = objContent.data('story_id');
                      var episode_id = objContent.data('episode_id');

                      var curent_episode = episodes_el.find('.episode-item[data-episode_id='+ episode_id +']');

                      var episode_index = curent_episode.index() + 1;

                      if (story_flag_completed && story_total == episode_index) {

                        if (window.innerHeight < 700) {
                          $(paginatePages[i]).find('.story_thubnail').remove();
                          $(paginatePages[i]).find('.authored').remove();
                        }

                        $(paginatePages[i]).append('<div id="related_stories" class="related_stories"></div>');

                        var related_stories = $(paginatePages[i]).find('#related_stories');
                        related_stories.loading(false);

                        $.get( DOMAIN + "/ajax/related_stories/" + story_id, function( data ) {
                          related_stories.html(data);

                          var ele = related_stories.find("#slick-related-stories");
                          var nav_id = "#slick-related-stories-nav";
                          __init_slick(ele, nav_id);
                        });

                      }

                    }
                    
                    // Handle button bookmark
                    __dispaly_bookmark_button(objContent);


                    lastPage = page;
                    // lastpageCharacters = pageCharacters;
                    
                    // and updating counter
                    updateCounter(page);

                }


            };

            __is_small_ads = function(small_ads_page_count, big_ads_page_count, page) {
              return  small_ads_page_count > 0 && 
                      big_ads_page_count > 0 &&
                      page % small_ads_page_count === 0 &&  
                      page % big_ads_page_count != 0;
            };

            __is_big_ads = function(big_ads_page_count, page) {
              return big_ads_page_count > 0 &&
                      page % big_ads_page_count === 0;
            };

            __the_end_of_pages = function(episode_id, authored, image_url) {
              
              var next_url = "";
              var prev_url = "";

              var episodes_obj = $('#episodes');
              var curent_episode = episodes_obj.find('.episode-item[data-episode_id='+ episode_id +']');

              if (typeof curent_episode != "undefined") {
                var next_episode = curent_episode.next('.episode-item');
                var prev_episode = curent_episode.prev('.episode-item');

                if (next_episode.length) {
                  next_url = next_episode.children('.btn_episode_detail').attr('href');
                  next_type = next_episode.children('.btn_episode_detail').data('type');
                }

                if (prev_episode.length) {
                  prev_url = prev_episode.children('.btn_episode_detail').attr('href');
                  prev_type = prev_episode.children('.btn_episode_detail').data('type');
                }
              }

              var str = '<div class="page end_of_page">' + 

                          '<p class="authored">អ្នកនិពន្ធ៖ '+ authored +'</p>' +

                          '<div class="story_thubnail">' +
                            '<div class="img" style="background-image:url(\''+ image_url +'\');"></div>' +
                          '</div>' +

                          '<div class="rate">' +
                            '<div class="p-title font-bold">វាយតម្លៃ</div>' +
                            '<input id="star_rating_episode" name="star_rating_episode" value="0" class="rating-loading">' +
                          '</div>' +

                          '<div class="btns">';
                if (prev_url != "") {
                    str += '<a href="'+ prev_url +'" class="btn_ep_next_prev prev btn btn-default flat" data-type="'+ prev_type +'">' +
                              ' <i class="glyphicon glyphicon-chevron-left"></i> ' +
                              ' ភាគមុន ' +
                           '</a>';
                }
                  str +=  '<a href="#" id="btn_share_activity" class="btn-fb flat btn">' +
                              '<i class="fa fa-facebook"></i> ចែករំលែក' +
                            '</a>';

                if (next_url != "") {
                    str += '<a href="'+ next_url +'" class="btn_ep_next_prev next btn btn-default flat" data-type="'+ next_type +'">' +
                              ' ភាគបន្ទាប់ ' +
                              ' <i class="glyphicon glyphicon-chevron-right"></i> ' +
                           '</a>';
                }
                  str +=  '</div>' +
												'</div>';



                return str;
            }

            __bookmark_list_ele = function(page, date, char, index) {
              return  '<li data-index="'+ index +'">' + 
                        '<a data-page="' + page + '" data-index="'+ index +'">' + 
                          '<div class="char">' + char + '</div>' + 
                          '<div class="date moment-calendar">' + date + '</div>' + 
                          '<div class="page">ទំព័រ ' + page + '</div>' + 
                          '<div class="clear"></div>' + 
                        '</a>' + 
                        '<i class="fa fa-trash trash" data-index="'+ index +'"></i>' + 
                      '</li>';
            }

            // show pagination function (draw switching numbers)
            showPagination = function(numPages) {
                var pagins = '';
                for (var i = 1; i <= numPages; i++) {
                    pagins += '<li id="'+i+'"><a href="#" onclick="showPage(' + i + '); return false;">' + i + '</a></li>';
                }
                $('.pagination li:first-child').after(pagins);
                $('.pagination li#1').addClass('active');
            };
            // perform initialization
            init();

            // and binding 2 events - on clicking to Prev
            $('.pagination #prev').off("click").click(function() {
                if(lastPage > 0) {
                  
                  showPage(lastPage-1);
                }
                return false;
            });

            // and Next
            var status = true;
            var next_status = false;
            $('.pagination #next').off("click").click(function() {
                var total_index = objContent.data('total_index');
                var reading_index = objContent.data('reading_index');
                var reading_percent = (reading_index * 100) / total_index;
               
                if(lastPage < options.numPages) {
                  
                  if (reading_percent >= 19.99 && ! IS_AUTH_USER) {
                    objContent.data('free_reading_index', reading_index);
                    $("#loginModal .modal-title").html('<i class="fa fa-exclamation-triangle text-warning" aria-hidden="true"></i> សូមធ្វើការ​ Login ដើម្បីអានបន្ត');
                    popup_login(); 
                    return false;
                  }

                  if (reading_percent >= 50 && IS_AUTH_USER) {
                    
                    objContent.data('free_reading_index', reading_index);
                    url = DOMAIN + '/no_permission' + '/' + objContent.data('story_id') + '/' + objContent.data('episode_id');
                    if (status) {
                      $.ajax({
                        url: url, 
                        method: "GET",
                        success: function(result) {
                          if (!result.status)	{					
                            $('body').append(detail_root_element());
                            var detail_el = $('#episode__detail');
                            if (detail_el.hasClass('episode__detail')) {
                              detail_el.addClass('episode__detail__audio')
                                        .css({'display':'block'});
                            }
                            display_no_permission(detail_el, result.view);
                            return false;
                          }
                          else {
                            next_status = true;
                          }
                          
                        }
                      });
                    }
                    status = false;

                    if (!next_status)
                      return false;
                  }
                  
                  __calculate_reading_speed();
                   
                  showPage(lastPage+1); 
                  
                }

                return false;
            });

        }
    });
})(jQuery);


function __swipe_episode_page(page) {
  // console.log(page.text());
  page.swipe( {
    swipe:function(event, direction, distance, duration, fingerCount, fingerData) {
      // console.log('swipe');
      switch(direction) {
          case "left":
              $('.pagination #next').trigger('click');
              break;

          case "right":
              $('.pagination #prev').trigger('click');
              break;
      } 
    }

  });
  
}
