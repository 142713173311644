(function($){
  $.fn.extend({ 
    isotopeStories: function(list) {
    	
			// init Isotope
    	var $grid = $(this).isotope({
			  itemSelector: '.item'
			});

			if (typeof list != "undefined") {
				$grid.append( list ).isotope( 'appended', list );
			}

			// store filter for each group
			var filters = [];

			// init = function() {
	  //     var $target = $('.btn-filter[data-filter="*"]').first();
	  //     doFilter($target);
	  //   }

			// change is-checked class on buttons
			$('.filter-ctrl').on( 'click', '.btn-filter', function( event ) {
			  var $target = $( event.currentTarget );

			  var isChecked = $target.hasClass('active');

			  if( isChecked ) {
			  	return false;
			  }

			  doFilter($target);
			  
			});


			function doFilter($target) {

			  var isMenu = $target.hasClass('a-menu');
			  var filter = $target.attr('data-filter');

				resetFilter();
			  $target.parents('.filter-ctrl-parent').find('.btn-filter').removeClass('active');

			  if( isMenu ) {
				  var menu_id = $target.data('id');
				  if( menu_id !== 0 ) {
					  $target.parents('.filter-ctrl-parent').find('.btn-filter').not('.a-menu').hide();
					  $target.parents('.filter-ctrl-parent').find('.btn-filter[data-parent="'+menu_id+'"]').show();
					} else {
						$target.parents('.filter-ctrl-parent').find('.btn-filter').show();
					}
				}
			  
			  var parent_id = $target.data('parent');
			  if (typeof parent_id != 'undefined') {
			  	$target.parents('.filter-ctrl-parent').find('.btn-filter[data-id="'+parent_id+'"]').addClass('active');
			  }

				filters.push( filter );
			  $target.toggleClass('active');
			  // filter isotope
			  // group filters together, inclusive
			  // console.log($grid);
			  $grid.isotope({ filter: filters.join(',') });
			}
			  
			function resetFilter() {
				filters = [];
			}
			  
			function addFilter( filter ) {
			  if ( filters.indexOf( filter ) == -1 ) {
			    filters.push( filter );
			  }
			}

			function removeFilter( filter ) {
			  var index = filters.indexOf( filter);
			  if ( index != -1 ) {
			    filters.splice( index, 1 );
			  }
			}


			// init();

    }
  });
})(jQuery);