
$(document).ready(function() {

	$('#notification').on('show.bs.dropdown', function () {
	  var url = DOMAIN + '/ajax/notifications';
	  var that = $(this);
	  $.get( url, function( data ) {
		  that.find('.notification-menu').html( data );

		  $('.btn-notification').on('click', function(e) {
				e.preventDefault();

				var action = $(this).data('action');
				var noti_id = $(this).data('id');
				var title = $(this).find('.title span').text();
				var msg = $(this).find('.msg').text();
				
				if ($('body').find('#episode__detail').length < 1) {
					$('body').append(subscription_root_element());
				}
				var detail_el = $('#episode__detail');
				detail_el.fadeIn();
				var body_el = detail_el.find('.detail_body');
				
				body_el.html(__redeem_promotion_root(title, msg));
				redeem_el = body_el.find('#redeem_promotion');

				if(action == '20_share_1_usd_gold') {
					var alert_msg = '<div class="alert alert-danger">'+
		  											'លោកអ្នកត្រូវបង់ប្រាក់បន្ថែម ដើម្បីទទួលបានការផ្តល់ជួននេះ'+
													'</div>';
					redeem_el.find('p').after(alert_msg);
				}

				body_el.find('.btn-redeem').on('click', function(e) {
					e.preventDefault();
					redeem_el.loading();
					switch(action) {
					    case "10_share_1_silver":
					        __redeem_promotion(redeem_el, noti_id);
					        break;

					    case "20_share_1_usd_gold":
					        __request_promotion_payment(redeem_el, noti_id)
					        break;
					}
				});

				detail_el.find('.blocker').on('click', function(e) {
					e.preventDefault();
					if(action == '20_share_1_usd_gold') {
						redeem_el.loading();
						setTimeout( __close_promotion,  10000);
					} else {
						__close_promotion();
					}
				});

			});
		});
	});




});

function __close_promotion() {
	__close($('#episode__detail'));
	__refresh_user();
}

function __request_promotion_payment(redeem_el, noti_id) {
	var url = DOMAIN + '/ajax/request_promotion_payment/' + noti_id;
	$.ajax({
    url: url, 
    method: 'POST', 
    success: function(result){
    	if(typeof result.status != 'undefined' && result.status) {
		  	var html = result.html;
				redeem_el.html(html);

				redeem_el.find('#close_promotion').on('click', function(e) {
					e.preventDefault();
					redeem_el.loading();
					setTimeout( __close_promotion,  10000);
				});
		  }
    }
  });

}


function __redeem_promotion(redeem_el, noti_id) {
	var url = DOMAIN + '/ajax/redeem_promotion/' + noti_id;
	$.ajax({
    url: url, 
    method: 'POST', 
    success: function(result){
    	if(typeof result.status != 'undefined' && result.status) {
    		redeem_el.html('<h4 class="text-center text-success">អរគុណសម្រាប់ការទទួលយក</h4>');
    		__refresh_user();
    	}
    }
  });
}


function __redeem_promotion_root(title, msg)
{
	return '<div id="redeem_promotion">'+
					'<div class="s-title">'+ title +'</div>'+
					'<p>'+ msg +'</p>'+
					'<button class="btn btn-danger flat btn-redeem">ទទួលយក</button>'+
				 '</div>';
}