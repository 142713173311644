
jQuery.fn.extend({
  ContentPhotoSwipe: function () {

    var galleryElement = $(this);

    var spwp_root = document.getElementById('spwp_root_element');
    
    if (spwp_root === null ) {
      $('body').append(spwp_root_element());
    }

    galleryElement.find('.story-item.picture').on('click', function(e) {
      e.preventDefault();

      var url = $(this).attr('href');
      ajax_url = url + '?ajax=true&return=picture';

      loadPhotoSwipe(ajax_url);
      
    });

    galleryElement.find('.story-item.my_quote').on('click', function(e) {
    	e.preventDefault();
      var image_url = $(this).data('image');
    	openPhotoSwipeSingle(image_url);
   		
    });
 		

    return;
  }
});

var loadPhotoSwipe = function(ajax_url) {
  $.ajax({
    url: ajax_url, 
    success: function(data){

      var story = data.story;
      // console.log(story.link);
      var btn_share = $('#enovel_share_story');
      btn_share.data('name', story.name);
      btn_share.data('caption', story.caption);
      btn_share.data('description', story.description);
      btn_share.data('link', story.link);
      btn_share.data('picture', story.picture);
      btn_share.data('story_id', story.story_id);
      btn_share.data('type', story.type);

      btn_share.unbind().click(function(e) {
        e.preventDefault();
        e.stopPropagation();
        var name = $(this).data('name');
        var caption = $(this).data('caption');
        var description = $(this).data('description');
        var link = $(this).data('link');
        var picture = $(this).data('picture');
        FB__PublishStory(link, $(this), __story_share_callback);
      });

      var episodes = data.episodes;

      openPhotoSwipe(data, 0, episodes);

    }
  });

};

var openPhotoSwipeSingle = function(image_url) {
  var pswpElement = document.querySelectorAll('.pswp')[0];

  $('.pswp').find('.pswp__button_enovel').hide();
    // define options (if needed)
  var items = [
    {
        src: image_url,
        w: 1000,
        h: 1000
    }
  ];

  // define options (if needed)
  var options = {
      // optionName: 'option value'
      // for example:
      index: 0 // start at first slide
  };

  // Initializes and opens PhotoSwipe
  var gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, items, options);
  gallery.init();
}


var openPhotoSwipe = function(data, index, episodes) {
    var pswpElement = document.querySelectorAll('.pswp')[0];

    $('.pswp').find('.pswp__button_enovel').show();

    // define options (if needed)
    var options = {
             // history & focus options are disabled on CodePen        
        history: false,
        index: index,
        showAnimationDuration: 0,
        hideAnimationDuration: 0,
        shareEl: false
        
    };
    var gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, data.images, options);
    gallery.init();

    // Image loaded
    gallery.listen('imageLoadComplete', function(index, item) { 
      var cur_epi = episodes[item.id];
      // piwik track
      if (typeof _paq != "undefined" && 
        typeof cur_epi != "undefined") {
        
        var detail_url = DOMAIN+"/book/"+cur_epi.story_id+"/"+cur_epi.episode_id;

        _paq.push(['setCustomUrl', detail_url]);
        _paq.push(['trackPageView']);
      }

    });

    var btn_rate = $('#enovel_rate_story')
    btn_rate.unbind().click(function(e) {
      e.preventDefault();
      e.stopPropagation();

      var cur = gallery.currItem;
      index = cur.id;
      var cur_epi = episodes[index];

      $(this).data('episode_id', cur_epi.episode_id);
      $(this).data('story_id', cur_epi.story_id);
      $(this).data('type', cur_epi.type);
      $(this).data('content_type', cur_epi.type);

      __rating_episode_enovel($(this));
    });

};

function spwp_root_element(custom) {
  return '<div id="spwp_root_element" class="pswp" tabindex="-1" role="dialog" aria-hidden="true">'+
    '<div class="pswp__bg"></div>'+
    '<div class="pswp__scroll-wrap">'+
        '<div class="pswp__container">'+
            '<div class="pswp__item"></div>'+
            '<div class="pswp__item"></div>'+
            '<div class="pswp__item"></div>'+
        '</div>'+
        '<div class="pswp__ui pswp__ui--hidden">'+
          '<div class="pswp__top-bar">'+
            '<div class="pswp__counter"></div>'+
              '<button class="pswp__button pswp__button--close" title="Close (Esc)"></button>'+
              '<button class="pswp__button pswp__button--share" title="Share"></button>'+
              '<button class="pswp__button pswp__button--fs" title="Toggle fullscreen"></button>'+
              '<button class="pswp__button pswp__button--zoom" title="Zoom in/out"></button>'+
              '<div class="pswp__preloader">'+
                  '<div class="pswp__preloader__icn">'+
                    '<div class="pswp__preloader__cut">'+
                      '<div class="pswp__preloader__donut"></div>'+
                    '</div>'+
                  '</div>'+
              '</div>'+
            // custome buttons
              '<button id="enovel_share_story" class="pswp__button pswp__button_enovel" title="Share"><i class="fa fa-facebook"></i> ចែករំលែក</button>'+
              '<button id="enovel_rate_story" class="pswp__button pswp__button_enovel" title="Rate"><i class="fa fa-star-half-o"></i> វាយតម្លៃ</button>'+

            '</div>'+
            '<div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">'+
                '<div class="pswp__share-tooltip"></div>'+
            '</div>'+
            '<button class="pswp__button pswp__button--arrow--left" title="Previous (arrow left)">'+
            '</button>'+
            '<button class="pswp__button pswp__button--arrow--right" title="Next (arrow right)">'+
            '</button>'+
            '<div class="pswp__caption">'+
                '<div class="pswp__caption__center"></div>'+
            '</div>'+
          '</div>'+
        '</div>'+
    '</div>';
}


