
function FB__PublishStory(link, element, fn_callback) {
  FB.ui({
    method: 'feed',
    link: link
  },
  function(response) {
    fn_callback(element, response);
  });
}


function FB__ShareLink(link, element, fn_callback) {
  FB.api(link, function(response) {
    fn_callback(element, response);
  });
}

function FB__shareActivity(action, object, element, fn_callback) {

  FB.ui({
    method: 'share_open_graph',
    action_type: action,
    action_properties: JSON.stringify(object)
  }, function(response){

    fn_callback(element, response);
  });

  // FB.api(
  //     "/me/" + action,
  //     "POST",
  //     object,

  //     function (response) {
  //       fn_callback(element, response);
  //     }
  // );
}

