(function($){
  $.fn.extend({ 
    loadCategoryStories: function(options) {

      var defaults = {
          displayElement: null
      };

      var options = $.extend(defaults, options);

      if (options.displayElement == null) {
      	return;
      }

      var displayElement = $(options.displayElement);

      var objContent = $(this);
      var lists = objContent.children('li');

      var first_cate = lists.first();

      if (typeof first_cate == "undefined") {
      	return;
      }

      lists.on('click', function(e) {
      	e.preventDefault();

      	if($(this).hasClass('active')) {
      		return;
      	}

      	__loadStories($(this));
      });

      init = function() {
	      __loadStories(first_cate);
	    }

      __loadStories = function(cate) {

        if (typeof _paq != "undefined") {
          _paq.push(['trackPageView']);
        }

      	displayElement.html('').loading(false);

      	cate.parent().children('li').removeClass('active');
      	cate.addClass('active');

      	var a_link = cate.find('a');
        var category_slug = a_link.data('category');
      	var type_slug = a_link.data('type');

      	var url =  DOMAIN + "/ajax/"+type_slug+"/category/"+ category_slug;
			  $.ajax({
			    url: url, 
			    method: "GET", 
			    success: function(result){
			    	displayElement.stop_loading();

			    	displayElement.html(result);

			    	$('.list_type_category').ContentPhotoSwipe();

			    	var ele = displayElement.find("#slick-"+category_slug+"-new");
			    	var nav_id = "#slick-"+category_slug+"-nav-new";
			    	__init_slick(ele, nav_id);

			    	var ele = displayElement.find("#slick-"+category_slug+"-popular");
			    	var nav_id = "#slick-"+category_slug+"-nav-popular";
						__init_slick(ele, nav_id);
						
						var ele = displayElement.find("#slick-"+category_slug+"-trending");
			    	var nav_id = "#slick-"+category_slug+"-nav-trending";
			    	__init_slick(ele, nav_id);

			    	var ele = displayElement.find("#slick-"+category_slug+"-all");
			    	var nav_id = "#slick-"+category_slug+"-nav-all";
			    	__init_slick(ele, nav_id);

			    	__init_tooggle_save_story();

			    	displayElement.children('.ads-items').each(function() {
			    		var zone_id = $(this).data('zone_id');
			    		$(this).html("<div class='ads_items'><a href='https://ads.sabay.com/openx/www/delivery/ck.php?n=ab79d61e&cb="+Math.random()*99999999999+"' target='_blank'><img src='https://ads.sabay.com/openx/www/delivery/avw.php?zoneid="+ zone_id +"&cb="+Math.random()*99999999999+"&n=ab79d61e' border='0' alt='' /></a></div>");
			    	});
			    }
			  });
      }


      init();

    }  
  });

})(jQuery);

