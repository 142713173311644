function moment_init(parent) {

  if(typeof parent != "undefined") {
    ele = parent.find('.moment-calendar');
  } else {
    ele = $('.moment-calendar');
  }
  
  ele.each(function() {
    var date = $.trim($(this).text());

    if (moment(date).isValid()) {
      localtime = moment.utc(date).toDate(); 
      $(this).text(moment(localtime).format('LL'));
    }

  });

}


$(document).ready(function(e) {
  moment_init();
});