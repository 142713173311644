
function popup_login(jqxhr, ajaxData) {
	$('#loginModal').modal('show')
	.on('shown.bs.modal', function (e) {

	  $('#btn-login-fb-modal').unbind('click').bind('click', function(e) {
			e.preventDefault();
			$(this).find('i').addClass('fa-spinner fa-pulse fa-fw');
		
			fb_login(ajaxData);
		

		});

	});
}


$('#btn-login-fb').on('click', function(e) {
	e.preventDefault();
	
 	fb_login();

});

function fb_login(ajaxData) {

	FB.login(function(response) {

    statusChangeCallback(response, ajaxData);

  }, {scope: 'user_friends'});

}

function statusChangeCallback(response, ajaxData) {
	if (response.status == 'connected') {
		var token = response.authResponse.accessToken;
		fb_post_login(token, ajaxData);
	
	} else {
		console.log('facebook login failed.');
	}
}

function fb_post_login(token, ajaxData) {

	$.post( DOMAIN+"/ajax/login/fb/"+token, function( res ) {

	  if (typeof res.status != "undefined" && res.status) {

	  	if (typeof ajaxData != "undefined") {
	  		
	  		__update_header_html();

	  		if ($('#manuLibrary').length && 
	  			!$('#manuLibrary').is(':visible')) {
	  			$('#manuLibrary').show();
	  		}

	  		if ($('#loginModal').is(':visible')) {
	  			$('#loginModal').modal('hide')
	  		}

	  		$.ajax(ajaxData);

	  	} else {
				detail_el = $("#episode__detail");
				var episode_detail = detail_el.find('#episode_detail');
		
				__update_reading_index(episode_detail);
				
				setTimeout(function() { 
					__refresh_user();
					location.reload(); 
				}, 3000);
					  		
	  	}


	  } else {
	  	console.log(res);
	  }

	});
}

function __update_reading_index(objContent) {

	var story_id = objContent.data('story_id');
  var total_index = objContent.data('total_index');
  var episode_id = objContent.data('episode_id');
  var episode_order = objContent.data('order');
  var content_type = objContent.data('content_type');
	var reading_index = objContent.data('free_reading_index');
	var story_el = $('#episodes');
	var story_total = story_el.data('total');
	var story_flag_completed = story_el.data('flag_completed');

	var episode_el = story_el.find('.episode-item[data-episode_id='+episode_id+']');
	var reading_order =  episode_el.index() + 1;
	var url =  DOMAIN + "/ajax/update_reading";
	
	$.ajax({
		url: url, 
		data: {
			'story_id': story_id,
			'episode_id': episode_id,
			'content_type': content_type,
			'total_index': total_index,
			'reading_index': reading_index,
			'reading_order': reading_order,
			'story_flag_completed': story_flag_completed,
			'story_total': story_total
		},
		method: "PUT", 
		success: function(result) {

			$('#btn_read_last_episode').data('reading_order', reading_order);

			// Piwik
			if (typeof _paq != "undefined" && 
					typeof result.new_story != "undefined" && 
					result.new_story) {
					_paq.push(['trackEvent', "User action", "added library", story_id]);
			}
		}
	});
}