
(function($){
  $.fn.extend({ 
    loadStoriesByAuthor: function() {
    	var contentObject = $(this);
    	var page = 1;
    	var author_id = contentObject.data('author_id');

    	var load_button = contentObject.find('#btn_more_author_story');

    	init = function() {
	      __loadStories(true);
	    }

      __loadStories = function(first_load) {

			  var content = contentObject.find('.stories_list');

			  var old_btn_text = load_button.html();

      	load_button.html('<i class="fa fa-spinner fa-spin"></i>');

      	var url =  DOMAIN + "/ajax/author/"+author_id+"/stories/"+ page;

			  $.ajax({
			    url: url, 
			    method: "GET", 
			    success: function(result) {

			    	load_button.html(old_btn_text);

			    	list = $.parseHTML( result.html );

			    	$(list).ContentPhotoSwipe();

			    	if (typeof first_load != "undefined" && first_load) {

				    	content.append(list).isotopeStories();

				    } else {

				    	content.isotopeStories(list);
				    }

			    	__init_tooggle_save_story();

						if (result.has_more == false) {
							load_button.remove();
						}

						page += 1;

			    }
			  });
      }


      load_button.on('click', function() {
      	__loadStories();
      });


      init();

    }  
  });

})(jQuery);

